import { Client } from "./client.model";

/**
 * @description contains Client information
 */
export class Transaction {

    id: string;
    orderId: string;
    serviceProvider: string;
    mnoName: string;
    countryCode: string;
    languageCode: string;
    channel: string;
    type: string;
    // sender: Client;
    fromCountryCode: string;
    // beneficiary: Client;
    toCountryCode: string;
    currencyCode: string;
    merchantReference: string;
    amount: number;
    state: string;
    createdAt: Date;
    status: string;
    statusCode: string;
    statusDescription: string;
    operatorTransactionId: string;

    commissionIrt: number;
    commissionBizao: number;
    phone: string;

    constructor(id: string, orderId: string, serviceProvider: string, mnoName: string, countryCode: string,
        languageCode: string, channel: string, type: string, fromCountryCode: string, toCountryCode: string, 
        currencyCode: string, merchantReference: string, amount: number, state: string, createdAt: Date,
        status: string, statusCode: string, statusDescription: string, operatorTransactionId: string,
        commissionIrt: number, commissionBizao: number, phone: string) {

            this.id = id;
            this.orderId = orderId;
            this.serviceProvider = serviceProvider;
            this.mnoName = mnoName;
            this.countryCode = countryCode;
            this.languageCode = languageCode;
            this.channel = channel;
            this.type = type;
            // this.sender = sender;
            this.fromCountryCode = fromCountryCode;
            // this.beneficiary = beneficiary;
            this.toCountryCode = toCountryCode;
            this.currencyCode = currencyCode;
            this.merchantReference = merchantReference;
            this.amount = amount;
            this.state = state;
            this.createdAt = createdAt;
            this.status = status;
            this.statusCode = statusCode;
            this.statusDescription = statusDescription;
            this.operatorTransactionId = operatorTransactionId;
            this.commissionIrt = commissionIrt;
            this.commissionBizao = commissionBizao;
            this.phone = phone;
    }

}
