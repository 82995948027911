import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Transaction } from '@app/_models/transaction';
import { TransactionService } from '@app/_services/transaction.service';
import { Subscription } from 'rxjs';
import { TransactionUtils } from '@app/utils/transactions.utils';

@Component({
	selector: 'app-global',
	templateUrl: './global.component.html',
	styleUrls: ['./global.component.css']
})
export class GlobalComponent implements OnInit, AfterViewInit {

	transactionSubscription: Subscription = new Subscription();
	transactions: Transaction[] = null;
	loader = false;

	totalAmount: number = 0;
	totalAmountSuccess: number = 0;
	totalFailedTransactions: number = 0;
	totalSuccessTransactions: number = 0;
	totalAbandonnedTransactions: number = 0;
	totalTransaction: number = 0;
	totalSucccessRevene = 0;
	transactionUtils = new TransactionUtils();

	successPourcentage = 0;
	failsPourcentage = 0;
	abandonnedPourcentage = 0;

	constructor(private transactionService: TransactionService) { }

	ngAfterViewInit(): void {}

	getLoader() {
		return this.transactionService.loader;
	}

	ngOnInit(): void {
		this.transactionService.transactionsSubject.subscribe(
			(transactions: any[]) => {
				this.transactions = transactions;
				this.totalSucccessRevene = this.transactionUtils.getAmountSumSuccessTransactions(this.transactions);
				this.calculateAllAmount();
			}
		);
		this.transactionService.emitTransactions();
		/* this.transactionSubscription = this.transactionService.transactionsSubject.subscribe(
			(transactions: any[]) => {
				this.transactions = transactions;
				this.calculateAllAmount();
				this.totalTransaction = this.transactions.length;
				this.loader = true;
			}
		);*/
	}

	calculateTotalAmount(transactions: Transaction[]) {
		let total = 0;
		transactions.forEach(transaction => {
			total += transaction.amount ? transaction.amount : 0;
		});

		return total;
	}

	calculateTotalSucceessAmount(transactions: Transaction[]) {
		let total = 0;
		transactions.forEach(transaction => {
			if (transaction.amount && (transaction.status == 'success' || transaction.status == 'successful')) {
				total += transaction.amount;
			}

		});

		return total;
	}

	getSuccessTransaction() {
		return this.transactions ? this.transactions.filter(transaction => transaction.status == 'success' || transaction.status == 'successful') : [];
	}

	getFailedTransaction() {
		return this.transactions ? this.transactions.filter(transaction => transaction.status == 'failed') : [];
	}

	getPourcentageSuccessTransaction() {
		return this.transactions ? parseInt(this.getSuccessTransaction().length / this.transactions.length * 100 + '') : 0;
	}

	getPourcentageFailedTransaction() {
		return this.transactions ? parseInt(this.getFailedTransaction().length / this.transactions.length * 100 + '') : 0;
	}

	calculateAllAmount() {
		this.totalSuccessTransactions = this.transactions.filter(transaction => transaction.status.toLowerCase() === 'successful').length;
		this.totalFailedTransactions = this.transactions.filter(transaction => transaction.status.toLowerCase() === 'failed').length;
		this.totalAbandonnedTransactions = this.transactions.filter(transaction => transaction.status.toLowerCase() === 'abandonned').length;

		this.successPourcentage = this.totalSuccessTransactions / this.transactions.length * 100;
		this.failsPourcentage = this.totalFailedTransactions / this.transactions.length * 100;
		this.abandonnedPourcentage = this.totalAbandonnedTransactions / this.transactions.length * 100;
	}

}
