import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

import { AccountService, AlertService, ProfilService } from '@app/_services';
import { User } from '@app/_models';

@Component({
    templateUrl: 'add-edit.component.html',
    styleUrls: ['./add-edit.component.css']
})
export class AddEditComponent implements OnInit {
    form: FormGroup;
    id: string;
    isAddMode: boolean;
    loading = false;
    submitted = false;
    profils = null;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private accountService: AccountService,
        private alertService: AlertService,
        private profilService: ProfilService
    ) {}

    ngOnInit() {
        this.id = this.route.snapshot.params['id'];
        this.isAddMode = !this.id;

        // password not required in edit mode
        const passwordValidators = [Validators.minLength(6)];
        if (this.isAddMode) {
            passwordValidators.push(Validators.required);
        }

        this.form = this.formBuilder.group({
            firstName:  ['', Validators.required],
            lastName:   ['', Validators.required],
            //username:   ['', Validators.required],
            emailAddress:      ['', [ Validators.required, Validators.email]],
            address:    ['', Validators.required],
            profil:     ['', Validators.required],
            // partner:    ['', Validators.required],
            phoneNumber:      ['', Validators.required]
            // password:   ['', passwordValidators]
        });

        this.initData();
    }

    initData() {
        this.profilService.getAll().subscribe(resp => {
            this.profils = resp;
            this.profils = this.profils.filter(p => p.name == 'user' || p.name == 'admin');

            if (!this.isAddMode) {
                // this.accountService.getById(this.id).pipe(first()).subscribe(profil => {
                this.accountService.getAll().subscribe(users => {
                    let user = users.filter(x => x.userId.toString() == this.id);

                    this.form.patchValue({
                        "address": user[0].address,
                        "emailAddress": user[0].emailAddress,
                        "firstName": user[0].firstName,
                        "lastName": user[0].lastName,
                        "phoneNumber": user[0].phoneNumber,
                        "profil": user[0]['profile']
                    });
                    console.log('user[0] : ', user[0], this.form.value);
                });        
            }
        });

    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }

        this.loading = true;
        if (this.isAddMode) {
            this.createUser();
        } else {
            this.updateUser();
        }
    }

    private createUser() {
        this.accountService.register(this.form.value)
            .subscribe({
                next: (data: any) => {
                    console.log('data user ********************* : ', data);
                    if(data.error && data.error === true) {
                        this.alertService.error("Erreur d'ajout : " + data.message);
                    } else {
                        console.log('this.form.value : ', this.form.value.profil);
                        let selectedProfil = this.profils.filter(p => p.id == this.form.value.profil);
                        this.accountService.assignRole(data.userId, selectedProfil).subscribe({
                            next: (data: any) => {
                                console.log('assign role : ', data);
                            }   
                        });

                        this.alertService.success('Utilisateur ajoutéavec succés !', { keepAfterRouteChange: true });
                        this.router.navigate(['../'], { relativeTo: this.route });
                    }
                },
                error: error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
            });
    }

    private updateUser() {
        this.accountService.update(this.id, this.form.value)
            .pipe(first())
            .subscribe({
                next: (data: any) => {
                    if(data.error && data.error === true) {
                        this.alertService.error("Erreur de modification : " + data.message, { keepAfterRouteChange: true });
                    } else {
                        this.alertService.success('Utilisateur modifié avec succés !', { keepAfterRouteChange: true });
                        this.router.navigate(['../../'], { relativeTo: this.route });
                    }
                },
                error: error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
            });
    }
}
