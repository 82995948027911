<div style="display: block;">
    <div class="col-lg-12 block-fliter">
        <div class="day-filter" [ngClass]="today ? 'active' : ''"
            (click)="changeSelectedRange('today')">
            Aujourd'hui
        </div>

        <div class="day-filter" [ngClass]="lastSevenDays ? 'active' : ''"
            (click)="changeSelectedRange('lastSevenDays')">
            7 derniers jours
        </div>

        <div class="day-filter" [ngClass]="lastThirtyDays ? 'active' : ''"
            (click)="changeSelectedRange('lastThirtyDays')">
            30 derniers jours
        </div>

        <div class="day-filter" [ngClass]="lastThreeMonth ? 'active' : ''"
            (click)="changeSelectedRange('lastThreeMonth')">
            3 derniers mois
        </div>

        <div class="day-filter" [ngClass]="tout ? 'active' : ''"
            (click)="changeSelectedRange('tout')">
            Tous
        </div>
    </div>
    <canvas baseChart width="400" height="210"
        [datasets]="lineChartData"
        [labels]="lineChartLabels"
        [options]="lineChartOptions"
        [colors]="lineChartColors"
        [legend]="lineChartLegend"
        [chartType]="lineChartType"
        [plugins]="lineChartPlugins">
    </canvas>
</div>
