import { Component, OnInit } from '@angular/core';
import { faUser, faLink, faDatabase } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { AccountService } from '@app/_services';
import { User } from '@app/_models';

@Component({
	selector: 'app-aside',
	templateUrl: './aside.component.html',
	styleUrls: ['./aside.component.css']
})
export class AsideComponent implements OnInit {

	faUserIcon = faUser;
	faLinkIcon = faLink;
	faDatabaseIcon = faDatabase;
	active = 'top';
	user: User;

	global = false;
	payment = false;
	users = false;

	constructor(private router: Router, private accountService: AccountService) { }

	ngOnInit(): void {
		this.user = this.accountService.userValue;
		console.log('url : ', this.router);
	}

	changeActive(e) {}

	changeActiveMenu(menu: string) {
		if (menu == 'payment') {
			this.payment = true;
			this.global = false;
			this.users = false;
		} else if (menu == 'users') {
			this.payment = false;
			this.global = false;
			this.users = true;
		} else {
			this.payment = false;
			this.global = true;
			this.users = false;
		}
	}

}
