import { Component, OnInit } from '@angular/core';
import { AccountService } from '@app/_services';
import { User } from '@app/_models';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {

	collapsed = true;

	user: User;

	constructor(private accountService: AccountService) {
		this.accountService.user.subscribe(x => this.user = x);
	}

	logout() {
		this.accountService.logout();
	}

	ngOnInit(): void {}

}
