<div class="col-md-12 breadcrumbs-block">
    <div class="row">
        
        <div class="col-md-6">
            <strong>App</strong> <span class="breadcrumbs-color"> > Configuration > Ajouter une offre</span>
        </div>

        <div class="col-md-6">
        </div>
    </div>
</div>

<div class="col-md-12 none-padding col-md-12 shadow-sm bg-white rounded pb-25">

    <div class="col-md-12 border-bottom block-header padding">
        <h5>
            Ajouter une offre
        </h5>
    </div>

    <div class="col-md-12">
        <div class="row">
            <div class="col-lg-6 col-md-6 offset-md-3">
                
                <form [formGroup]="form" (ngSubmit)="onSubmit()">

                    <div class="form-group row mt-25">
                        <label for="colFormLabel" class="col-sm-2 col-form-label">Formule</label>
                        <div class="col-sm-10">

                            <select class="form-control" id="offer" name="offer"
                                formControlName="offer"
                                [ngClass]="{ 'is-invalid': submitted && f.offer.errors }" required>

                                <option value="" disabled>Sélectionnez une formule</option>
                                <option
                                    *ngFor="let formule of formules"
                                    [selected] = "form.value.offer == formule.id"
                                    [ngValue]="formule.id">
                                    {{ formule.label }}
                                </option>

                            </select>
                            <span *ngIf="!formules" class="spinner-border spinner-border-sm mr-1 spinner-select"></span>

                            <div *ngIf="submitted && f.offer.errors" class="invalid-feedback">
                                <div *ngIf="f.offer.errors.required">Formule est obligatoire</div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row mt-25">
                        <label for="duration" class="col-sm-2 col-form-label">Durée</label>
                        <div class="col-sm-10">

                            <select class="form-control" id="duration" name="duration"
                                formControlName="duration"
                                [ngClass]="{ 'is-invalid': submitted && f.duration.errors }" required>

                                <option value="" disabled>Sélectionnez une durée</option>
                                <option 
                                    *ngFor="let duration of durations"
                                    [selected] = "form.value.duration == duration.id"
                                    [ngValue]="duration.id">
                                    {{ duration.label }}
                                </option>

                            </select>
                            <span *ngIf="!durations" class="spinner-border spinner-border-sm mr-1 spinner-select"></span>

                            <div *ngIf="submitted && f.duration.errors" class="invalid-feedback">
                                <div *ngIf="f.duration.errors.required">La durée est obligatoire</div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row mt-25">
                        <label for="colFormLabel" class="col-sm-2 col-form-label">Montant</label>
                        <div class="col-sm-10">
                            <input type="number" class="form-control" id="price" 
                                formControlName="price" class="form-control" 
                                [ngClass]="{ 'is-invalid': submitted && f.price.errors }"
                                placeholder="Montant">

                            <div *ngIf="submitted && f.price.errors" class="invalid-feedback">
                                <div *ngIf="f.price.errors.required">Le montant est obligatoire</div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row mt-25">
                        <label for="exampleFormControlTextarea1" class="col-sm-2 col-form-label">Description</label>
                        <div class="col-sm-10">
                            <textarea class="form-control" name="description" 
                                formControlName="description" id="description" rows="3"></textarea>
                        </div>
                    </div>

                    <div class="form-group row mt-25">
                        <div class="col-sm-6 offset-sm-3">
                            <div class="row">
                                <div class="col-sm-6">
                                    <a [routerLink]="['/offers']" routerLinkActive="router-link-active" >
                                        <button type="reset" class="white-button">Annuler</button>
                                    </a>
                                </div>
                                <div class="col-sm-6">
                                    <button [disabled]="loading" class="orange-button" type="submit">
                                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                        Enregistrer
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </form>
            </div>
            <div id="login-img" class="col s6 cus-h-450 hide-on-small-only"></div>
        </div>
    </div>

</div>
