import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Offer } from '@app/_models';

@Injectable({ providedIn: 'root' })
export class OfferService {

    private offerSubject: BehaviorSubject<Offer>;
    public offer: Observable<Offer>;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.offerSubject = new BehaviorSubject<Offer>(new Offer(null, null, 0, '', false));
        this.offer = this.offerSubject.asObservable();
    }

    public get offerValue(): Offer {
        return this.offerSubject.value;
    }

    add(offer: Offer) {
        return this.http.post(`${environment.ppApi}/packages`, offer);
    }

    getAll() {
        return this.http.get<Offer[]>(`${environment.ppApi}/packages`);
    }

    getById(id: string) {
        return this.http.get<Offer>(`${environment.ppApi}/packages/${id}`);
    }

    update(id, params) {
        return this.http.put(`${environment.ppApi}/packages/${id}`, params)
            .pipe(map(x => {
                return x;
            }));
    }

    delete(id: number) {
        return this.http.delete(`${environment.ppApi}/packages/${id}`)
            .pipe(map(x => {
                return x;
            }));
    }
}
