import { Component, OnInit } from '@angular/core';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faCopy, faEnvelope, faMailBulk } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { ValidateRouter } from '@app/_shared/router.validator';

import { PaymentService, AlertService, OfferService } from '@app/_services';
import { Offer, Payment } from '@app/_models';
import { environment } from '@environments/environment';

@Component({
	selector: 'app-payment',
	templateUrl: './payment.component.html',
	styleUrls: ['./payment.component.css']
})

export class PaymentComponent implements OnInit {

	faCopy = faCopy;
	faEnvelope = faEnvelope;
	faMailBulk = faMailBulk;
	generatedLink: Payment;
	lien = '';
	closeResult = '';
	submitted = false;
	form: FormGroup;
	loading = false;
	loadingSendMail = false;
	loadingSendMailStatus = false;
	packages: Offer[];
	countries: string[];
	message: string = `Cher [nom], 

Se réabonner sans se déplacer c’est possible ! Veuillez cliquer sur ce lien [lien] pour procéder au paiement quel que soit votre opérateur mobile money ou à l’aide de votre carte VISA ou MASTERCARD.

Pour plus d’informations, contactez le 1313 ou contactez-nous par mail à relationclientcanalboxcotedivoire@gva.africa.`;


	constructor(
		private modalService: NgbModal,
		private formBuilder: FormBuilder,
		private paymentService: PaymentService,
		private offerService: OfferService,
		private alertService: AlertService,
		private router: Router,
		private route: ActivatedRoute
	) {
	}

	get f() { return this.form.controls; }

	ngOnInit(): void {

		

		this.paymentService.generateReference().subscribe(reference => {
			console.log('reference : ', reference);
			// this.form.value.pnr = reference.data;
			this.form.controls['pnr'].setValue(reference.data);
		});

		this.form = this.formBuilder.group({
			firstName: ['', Validators.required],
			lastName: ['', Validators.required],
			email: ['', [Validators.required, Validators.email]],
			country: ['', Validators.required],
			phone: ['', Validators.required],
			pnr: ['', Validators.required],
			time: ['', Validators.required],
			message: ['', Validators.required],
			amount: ['', Validators.required]
		});

		this.form.controls['country'].setValue('ci');
		this.form.controls['message'].setValue(`Cher client [nom],

Veuillez cliquer sur le lien [lien] suivant votre réservation depuis votre compte Mobile Money ou votre carte bancaire (Visa/MasterCard).

Noter que pour des raisons de sécurité, ce lien expirera dans [validite].`);
	}

	onSubmitUserData() {
		/* Generate link from back-office */
		this.submitted = true;
		console.log('this.form.value : ', this.form.value);
		let generateLinkData = {
			"link": {
				"transactionReference": this.form.value.pnr,
				"validity": this.form.value.time,
				"amount": this.form.value.amount
			},
			"customer": {
				"firstName": this.form.value.firstName,
				"lastName": this.form.value.lastName,
				"email": this.form.value.email,
				"phoneNumber": this.form.value.phone
			}
		};
		console.log('generateLinkData : ', generateLinkData);
		// reset alerts on submit
		// this.alertService.clear();

		// stop here if form is invalid
		if (this.form.invalid) {
			return;
		} else {
			this.loading = true;
			this.paymentService.createPaymentLink(generateLinkData)
				.pipe(first())
				.subscribe(generatedLink => {
					console.log('generatedLink : ', generatedLink['data'].transactionReference);
					this.generatedLink = <Payment>generatedLink;
					this.lien = environment.link + '' + generatedLink['data'].transactionReference;// this.generatedLink.link;
					this.loading = false;
				});
		}
	}

	sendLink(form: NgForm) { }

	sendLinkViaMail() {
		if (this.form.invalid) {
			return;
		} else {
			this.loadingSendMail = true;
			this.paymentService.sendLinkViaMail(new Payment(this.lien))
				.pipe(first())
				.subscribe(retour => {
					this.loadingSendMail = false;
					this.alertService.success("Lien envoyé !", { keepAfterRouteChange: true });
					this.loadingSendMailStatus = true;
					// this.form.reset(this.form.value);
					// this.router.navigate(['/'], { relativeTo: this.route });
				});
		}
	}

	open(content: any) {
		if (this.form.invalid && this.lien) {
			return;
		} else {
			if (this.lien) {
				this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(result => {
					this.closeResult = `Closed with: ${result}`;
				}, (reason) => {
					this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
				});
			} else {
				this.alertService.error("Le lien n'est pas encoré créé !");
			}
		}
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

	sendLinkToWhatsapp() {
		let number = this.form.value.phone;
		let message = this.form.value.message
			.replace('[nom]', this.form.value.firstName)
			.replace('[lien]', this.lien)
			.replace('[validite]', this.form.value.time);

		let url = `https://api.whatsapp.com/send?phone=' + ${number} + '&text=%20' + ${encodeURIComponent(message)}`;
		window.open(url);
	}

}
