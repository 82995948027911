import { Transaction, Client, IrtCommission } from "@app/_models/index";

export class TransactionUtils {
    rawDataTOTransaction (raw) {

        /* let sender = new Client(
            raw.sender_first_name,
            raw.sender_last_name,
            raw.sender_address,
            raw.sender_msisdn
        );

        let beneficiary = new Client(
            raw.beneficiary_first_name,
            raw.beneficiary_last_name,
            raw.beneficiary_address,
            raw.beneficiary_msisdn
        ); */

        return new Transaction(
            raw.transaction_id,
            raw.order_id,
            raw.service_provider,
            raw.mno_name,
            raw.country_code,
            raw.language_code,
            raw.channel,
            raw.type,
            // sender,
            raw.from_country,
            // beneficiary,
            raw.country,
            raw.currency,
            raw.reference_id,
            parseInt(raw.amount),
            raw.state,
            new Date(raw.transaction_timestamp),
            raw.payment_status,
            raw.payment_status,
            raw.status_description,
            raw.transaction_id,
            raw.amount,
            raw.amount,
            raw.user_msisdn
        );
    };

    rawDataToIrtCommission (raw) {
        return new IrtCommission(
            raw.currency_code,
            raw.service_provider,
            raw.turnover_balance,
            raw.settlement_balance,
            raw.balance,
            raw.created_date
        );
    };

    getAmountSumTransactions(transactions: Transaction[]) {
        let amontArray = [];
        transactions.forEach(transaction => {
            amontArray.push(parseInt(transaction.amount.toString()));
        });
        return amontArray.reduce((sum, current) => sum + current, 0);
    }

    getAmountSumSuccessTransactions(transactions: Transaction[]) {
        let amontArray = [];
        let successTransactions = transactions.filter(transaction => transaction.status === 'Successful');
        successTransactions.forEach(transaction => {
            amontArray.push(parseInt(transaction.amount.toString()));
        });
        return amontArray.reduce((sum, current) => sum + current, 0);
    }
}
