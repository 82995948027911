export class User {

    userId: number;
    password: string;
    firstName: string;
    lastName: string;
    emailAddress: string;
    address: string;
    profil: string;
    partner: string;
    phoneNumber: string;
    status: boolean;
    access_token: string;

    constructor() {}

    setFirstName(firstname: string) {
        this.firstName = firstname;
    }

    setLastName(lastname: string) {
        this.lastName = lastname;
    }

    setEmail(emailAddress: string) {
        this.emailAddress = emailAddress;
    }

    setAddress(address: string) {
        this.address = address;
    }

    setProfil(profil: string) {
        this.profil = profil;
    }

    setPartner(partner: string) {
        this.partner = partner;
    }

    setPhone(phone: string) {
        this.phoneNumber = phone;
    }

    setAccessToken(token: string) {
        this.access_token = token;
    }

}
