import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AccountService } from './_services';
import { User } from './_models';
import { BnNgIdleService } from 'bn-ng-idle';
import { AlertService } from '@app/_services';

@Component({ 
    selector: 'app',
    templateUrl: 'app.component.html',
    styleUrls: ['./app.component.css']
})

export class AppComponent {
    user: User;

    constructor(
        private accountService: AccountService,
        private bnIdle: BnNgIdleService, 
        private alertService: AlertService,
        private router: Router
    ) {
        this.accountService.user.subscribe(x => this.user = x);
        this.bnIdle.startWatching(900).subscribe((res) => {
            if(res) {
                // this.alertService.success("Vous avez été déconnecté pour cause d'inactivité!", { keepAfterRouteChange: true });
                this.logout();
            }
        });
    }

    logout() {
        this.accountService.logout();
    }
}
