<div class="container-fluid">
    <div class="row">

        <div class="row">
            <div class="text-center col-lg-12 alert-icon">
                <img src="/assets/img/icons/Popup/alerte.svg" alt="Logo Bizao" width="50px" style="margin-right: 10px;"/>
            </div>
            <h6 class="col-lg-12 text-center popup-title">
                Etes vous sur de vouloir <strong>supprimer</strong> cet utilidateur ?
            </h6>
        
            <div class="col-lg-12 action-popup-button">
                <div class="row">
                    <div class="col-lg-6">
                        <button class="white-button float-right content-shadow" mat-dialog-close
                            type="button" (click)="dismissDialog()">
                            NON
                        </button>
                    </div>

                    <div class="col-lg-6">
                        <button class="orange-button float-left content-shadow" routerLink="add" 
                            (click)="processDelete()" style="text-decoration: none;">
                            OUI
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
