import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color } from 'ng2-charts';
import { Transaction } from '@app/_models/transaction';
import { TransactionService } from '@app/_services/transaction.service';
import { Subscription } from 'rxjs';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-chart',
	templateUrl: './chart.component.html',
	styleUrls: ['./chart.component.css']
})

export class ChartComponent implements OnInit, AfterViewInit {

	transactions: Transaction[];
	fullTransactions: Transaction[];
	weekRange: any[];
	transactionSubscription: Subscription;

	dataChart = [];
	successDataChart = [];
	failedDataChart = [];
	abandonedDataChart = [];

	currentDate: Date;

	today: boolean = true;
	lastSevenDays: boolean = false;
	lastThreeMonth: boolean = false;
	lastThirtyDays: boolean = false;
	tout: boolean = false;

	public lineChartData: ChartDataSets[] = [
		{ data: this.dataChart, label: 'Transactions' },
		{ data: this.successDataChart, label: 'Transactions success' }
	];

	dayTab = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
	monthTab = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre',
		'Novembre', 'Décembre'];
	hourTab = ['00h00', '01h00', '02h00', '03h00', '04h00', '05h00', '06h00', '07h00', '08h00', '09h00', '10h00',
		'11h00', '12h00', '13h00', '14h00', '15h00', '16h00', '17h00', '18h00', '19h00', '20h00',
		'21h00', '22h00', '23h00'];

	public lineChartLabels = [];
	public lineChartOptions: ChartOptions = {
		responsive: true,
	};

	public lineChartColors: Color[] = [
		{
			borderColor: '#81d149',
			backgroundColor: 'transparent',
		},
		{
			borderColor: '#d1351f',
			backgroundColor: 'transparent',
		},
		{
			borderColor: '#004085',
			backgroundColor: 'transparent',
		},
	];

	public lineChartLegend = false;
	public lineChartType = 'line';
	public lineChartPlugins = [];

	constructor(private transactionService: TransactionService) { }

	ngAfterViewInit(): void {
	}

	ngOnInit(): void {
		this.transactionSubscription = this.transactionService.transactionsSubject.subscribe(transactions => {
			this.transactions = transactions;
			console.log('transactions chart : ', this.transactions);
			// this.loadDataChartFromTransactionSize();
			// this.loadDataChartTrafic();
			this.buildDayChart();
			/* if (this.today) {
				this.buildDayChart();
			} else if(this.lastSevenDays) {
				this.filterByLastDays(7);
				this.loadDataChartTrafic();
			} else if(this.lastThreeMonth) {
				this.filterByLastDays(30);
				this.loadDataChartTrafic();
			} else if(this.lastThirtyDays) {
				this.filterByLastDays(90);
				this.loadDataChartTrafic();
			} else if (this.tout) {
				this.filterByLastDays(0);
				this.loadDataChartTrafic();
			}*/
		});
	}

	getTotalWeeklyTransactions() { }

	getTransactionChartDataFromamount() {
		this.dataChart = [];
		this.lineChartLabels = [];

		this.transactions.forEach((transc, i) => {

			if (i === 0) {
				this.dataChart[0] = transc.amount == null ? 0 : transc.amount;
				let dayIndex = new Date(transc.createdAt).getDay();
				this.lineChartLabels[0] = this.dayTab[dayIndex - 1] + ', ' 
					+ new Date(transc.createdAt).getDate() + ', ' 
					+ this.monthTab[new Date(transc.createdAt).getMonth() + 1];;
			} else {
				let transactionDate = new Date(transc.createdAt);
				let transactionNgDate = new NgbDate(transactionDate.getFullYear(), transactionDate.getMonth() + 1, transactionDate.getDate());

				let previousTransactionDate = new Date(this.transactions[i - 1].createdAt);
				let previousTransactionNgDate = new NgbDate(
					previousTransactionDate.getFullYear(), previousTransactionDate.getMonth() + 1, previousTransactionDate.getDate()
				);

				if (transactionNgDate.equals(previousTransactionNgDate)) {
					let amount = transc.amount == null ? 0 : transc.amount;

					this.dataChart[this.dataChart.length - 1] += amount;
					let dayIndexPrevious = new Date(transc.createdAt).getDay();
					this.lineChartLabels[this.dataChart.length - 1] =
						this.dayTab[dayIndexPrevious - 1] + ', ' + new Date(transc.createdAt).getDate() + ', ' 
						+ this.monthTab[new Date(transc.createdAt).getMonth() + 1];;
				} else {
					this.dataChart[this.dataChart.length] = transc.amount;
					let newDateIndex = new Date(transc.createdAt).getDay();
					this.lineChartLabels[this.dataChart.length] = this.dayTab[newDateIndex - 1] + ', ' 
						+ new Date(transc.createdAt).getDate() + ', ' 
						+ this.monthTab[new Date(transc.createdAt).getMonth() + 1];
				}
			}
		});
		this.lineChartData = [
			{ data: this.dataChart, label: 'Transactions' },
		];
	}

	loadDataChartFromTransactionSize() {
		this.dataChart = [];
		this.successDataChart = [];
		this.lineChartLabels = [];
		this.transactions.slice().reverse().forEach((transc, i) => {
			console.log('month : ', this.monthTab[new Date(transc.createdAt).getMonth()]);
			if (i <= this.transactions.length - 1) {
				if (i === 0) {
					this.dataChart[0] = 1;

					this.successDataChart[0] = (transc.status == 'success' || transc.status == 'successful') ? 1 : 0;

					let dayIndex = new Date(transc.createdAt).getDay();
					this.lineChartLabels[0] = this.dayTab[dayIndex] + ', ' 
						+ new Date(transc.createdAt).getDate() + ', ' 
						+ this.monthTab[new Date(transc.createdAt).getMonth() + 1];
				} else {
					let transactionDate = new Date(transc.createdAt);
					let transactionNgDate = new NgbDate(
						transactionDate.getFullYear(),
						transactionDate.getMonth() + 1,
						transactionDate.getDate()
					);

					let previousTransactionDate = new Date(this.transactions.slice().reverse()[i - 1].createdAt);
					let previousTransactionNgDate = new NgbDate(
						previousTransactionDate.getFullYear(),
						previousTransactionDate.getMonth() + 1,
						previousTransactionDate.getDate()
					);

					if (transactionNgDate.equals(previousTransactionNgDate)) {
						this.dataChart[this.dataChart.length - 1]++;
						let dayIndexPrevious = new Date(transc.createdAt).getDay();

						this.lineChartLabels[this.dataChart.length - 1] = this.dayTab[dayIndexPrevious] + ', ' 
							+ new Date(transc.createdAt).getDate() + ', ' 
							+ this.monthTab[new Date(transc.createdAt).getMonth() + 1];

						if (transc.status == 'success' || transc.status == 'successful') {
							let successTransc = this.successDataChart[this.dataChart.length - 1];
							this.successDataChart[this.dataChart.length - 1] = successTransc ? successTransc + 1 : 1;
						} else {
							let successTransc = this.successDataChart[this.dataChart.length - 1];
							this.successDataChart[this.dataChart.length - 1] = successTransc ? successTransc : 0;
						}
					} else {
						this.dataChart[this.dataChart.length] = 1;
						let newDateIndex = new Date(transc.createdAt).getDay();
						this.lineChartLabels[this.dataChart.length - 1] = this.dayTab[newDateIndex] + ', ' 
							+ new Date(transc.createdAt).getDate() + ', ' 
							+ this.monthTab[new Date(transc.createdAt).getMonth() + 1];

						this.successDataChart[this.dataChart.length - 1] = (transc.status == 'success' || transc.status == 'successful') ? 1 : 0;
					}
				}
			}
		});

		this.lineChartData = [
			{ data: this.dataChart, label: 'Transactions' },
			{ data: this.successDataChart, label: 'Transactions success' },
		];
	}

	buildDayChart() {
		this.dataChart = [];
		this.successDataChart = [];
		this.lineChartLabels = [];

		let from = new Date();
		let fromNgDate = new NgbDate(
			from.getFullYear(),
			from.getMonth() + 1,
			from.getDate()
		);		

		this.transactions.slice().forEach((transc, i) => {
			let transactionDate = new Date(transc.createdAt);
			let transactionNgDate = new NgbDate(
				transactionDate.getFullYear(),
				transactionDate.getMonth() + 1,
				transactionDate.getDate()
			);
			
			if (fromNgDate.equals(transactionNgDate)) {
				if (i <= this.transactions.length - 1) {
					if (i === 0) {
						this.dataChart[0] = 1;
	
						this.successDataChart[0] = (transc.status == 'success' || transc.status == 'successful') ? 1 : 0;
	
						let dayIndex = new Date(transc.createdAt).getHours();
						this.lineChartLabels[0] = this.hourTab[dayIndex];
					} else {
						let transactionDate = new Date(transc.createdAt);
						let previousTransactionDate = new Date(this.transactions.slice().reverse()[i - 1].createdAt);
	
						if (transactionDate.getHours() == previousTransactionDate.getHours()) {
							this.dataChart[this.dataChart.length - 1]++;
							let dayIndexPrevious = new Date(transc.createdAt).getDay();
	
							this.lineChartLabels[this.dataChart.length - 1] = this.hourTab[dayIndexPrevious];
	
							if (transc.status == 'success' || transc.status == 'successful') {
								let successTransc = this.successDataChart[this.dataChart.length - 1];
								this.successDataChart[this.dataChart.length - 1] = successTransc ? successTransc + 1 : 1;
							} else {
								let successTransc = this.successDataChart[this.dataChart.length - 1];
								this.successDataChart[this.dataChart.length - 1] = successTransc ? successTransc : 0;
							}
						} else {
							this.dataChart[this.dataChart.length] = 1;
							let newDateIndex = new Date(transc.createdAt).getDay();
							this.lineChartLabels[this.dataChart.length - 1] = this.hourTab[newDateIndex];
	
							this.successDataChart[this.dataChart.length - 1] = (transc.status == 'success' || transc.status == 'successful') ? 1 : 0;
						}
					}
				}
			}
		});

		this.lineChartData = [
			{ data: this.dataChart, label: 'Transactions' },
			{ data: this.successDataChart, label: 'Transactions success' },
		];
	}

	isTransactionDateEquals() {
	}

	changeSelectedRange(range: string) {
		console.log('range : ', range);
		if(range == 'today') {
			this.today = true;
			this.lastSevenDays = false;
			this.lastThreeMonth = false;
			this.lastThirtyDays = false;
			this.tout = false;
			this.buildDayChart();
		} else if (range == 'lastSevenDays') {
			this.today = false;
			this.lastSevenDays = true;
			this.lastThreeMonth = false;
			this.lastThirtyDays = false;
			this.tout = false;

			this.filterByLastDays(7);
			this.loadDataChartTrafic();
		} else if (range == 'lastThirtyDays') {
			this.today = false;
			this.lastSevenDays = false;
			this.lastThreeMonth = false;
			this.lastThirtyDays = true;
			this.tout = false;

			this.filterByLastDays(30);
			this.loadDataChartTrafic();
		} else if (range == 'lastThreeMonth') {
			this.today = false;
			this.lastSevenDays = false;
			this.lastThreeMonth = true;
			this.lastThirtyDays = false;
			this.tout = false;

			this.filterByLastDays(90);
			this.loadDataChartTrafic();
		} else {
			this.today = false;
			this.lastSevenDays = false;
			this.lastThreeMonth = false;
			this.lastThirtyDays = false;
			this.tout = true;

			this.filterByLastDays(0);
			this.loadDataChartTrafic();
		}
	}

	loadDataChartTrafic() {
		this.dataChart = [];
		this.failedDataChart = [];
		this.successDataChart = [];
		this.abandonedDataChart = [];
		this.lineChartLabels = [];
		this.transactions.slice().reverse().forEach((transc, i) => {
			if (i <= this.transactions.length - 1) {
				if (i === 0) {
					this.failedDataChart[0] = (transc.status.toLocaleLowerCase() == 'failed' || transc.status.toLocaleLowerCase() == 'fail') ? 1 : 0;
					this.successDataChart[0] = (transc.status.toLocaleLowerCase() == 'success' || transc.status.toLocaleLowerCase() == 'successful') ? 1 : 0;
					this.abandonedDataChart[0] = (transc.status.toLocaleLowerCase() == 'abandonned' || transc.status.toLocaleLowerCase() == 'abandon') ? 1 : 0;

					let dayIndex = new Date(transc.createdAt).getDay();
					this.lineChartLabels[0] = this.dayTab[dayIndex] + ', ' 
						+ new Date(transc.createdAt).getDate() + ' ' 
						+ this.monthTab[new Date(transc.createdAt).getMonth()];;
				} else {
					let transactionDate = new Date(transc.createdAt);
					let transactionNgDate = new NgbDate(
						transactionDate.getFullYear(),
						transactionDate.getMonth() + 1,
						transactionDate.getDate()
					);

					let previousTransactionDate = new Date(this.transactions.slice().reverse()[i - 1].createdAt);
					let previousTransactionNgDate = new NgbDate(
						previousTransactionDate.getFullYear(),
						previousTransactionDate.getMonth() + 1,
						previousTransactionDate.getDate()
					);

					if(transactionNgDate.equals(previousTransactionNgDate)) {
						this.dataChart[this.dataChart.length - 1] ++;
						let dayIndexPrevious = new Date(transc.createdAt).getDay();

						if (transc.status.toLocaleLowerCase() == 'success' || transc.status.toLocaleLowerCase() == 'successful') {
							let successTransc = this.successDataChart[this.dataChart.length - 1];
							this.successDataChart[this.dataChart.length - 1] = successTransc ? successTransc + 1 : 1;
						} else if(transc.status.toLocaleLowerCase() == 'failed' || transc.status.toLocaleLowerCase() == 'fail') {
							let failedTransc = this.failedDataChart[this.dataChart.length - 1];
							this.failedDataChart[this.dataChart.length - 1] = failedTransc ? failedTransc + 1 : 1;
						} else if(transc.status.toLocaleLowerCase() == 'abandonned' || transc.status.toLocaleLowerCase() == 'abandon') {
							let failedTransc = this.abandonedDataChart[this.dataChart.length - 1];
							this.abandonedDataChart[this.dataChart.length - 1] = failedTransc ? failedTransc + 1 : 1;
						}
					} else {
						this.dataChart[this.dataChart.length] = 1;
						let newDateIndex = new Date(transc.createdAt).getDay();
						this.lineChartLabels[this.dataChart.length - 1] = this.dayTab[newDateIndex] + ', ' 
							+ new Date(transc.createdAt).getDate() + ' ' 
							+ this.monthTab[new Date(transc.createdAt).getMonth()];;

						this.successDataChart[this.dataChart.length - 1] = (transc.status.toLocaleLowerCase() == 'success' || transc.status.toLocaleLowerCase() == 'successful') ? 1 : 0;
						this.failedDataChart[this.dataChart.length - 1] = (transc.status.toLocaleLowerCase() == 'failed' || transc.status.toLocaleLowerCase() == 'fail') ? 1 : 0;
						this.abandonedDataChart[this.dataChart.length - 1] = (transc.status.toLocaleLowerCase() == 'abandonned' || transc.status.toLocaleLowerCase() == 'abandon') ? 1 : 0;
					}
				}
			}
		});

		this.lineChartData = [
			{ data: this.successDataChart, label: 'Success' },
			{ data: this.failedDataChart, label: 'Failed' },
			{ data: this.abandonedDataChart, label: 'Abandonnés' }
		];
	}

	filterByLastDays(days: number) {
		if (days == 0) {
			this.transactionService.setAllData();
		} else {
			let from = new Date();
			let to = new Date();
			from.setDate(to.getDate() - days);

			let fromDate = new NgbDate(from.getFullYear(), from.getMonth() + 1, from.getDate());
			let toDate = new NgbDate(to.getFullYear(), to.getMonth() + 1, to.getDate());

			this.transactionService.setWeekRangeFromTab({
				firstday: fromDate,
				lastday: toDate
			});
		}
	}


}
