import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';

import { UsersRoutingModule } from './users-routing.module';
import { LayoutComponent } from './layout.component';
import { ListComponent } from './list.component';
import { AddEditComponent } from './add-edit.component';
import { UiSwitchModule } from 'ngx-ui-switch';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPaginationModule } from 'ngx-pagination'; // <-- import the module
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { MatDialogModule } from '@angular/material/dialog';


@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        UsersRoutingModule,
        UiSwitchModule.forRoot({
            size: 'small',
            color: 'rgb(0, 189, 99)',
            switchColor: '#1ba231',
            defaultBgColor: '#b9e4c0',
            defaultBoColor : '#b9e4c0',
            checkedLabel: 'on'
        }),
        NgbDropdownModule,
        NgxPaginationModule,
        Ng2SearchPipeModule,
        MatDialogModule
    ],
    declarations: [
        LayoutComponent,
        ListComponent,
        AddEditComponent
    ],
    providers: [
        {​​​​​​​​provide: LocationStrategy, useClass: HashLocationStrategy}​​​​​​​​
    ],
})
export class UsersModule { }