/**
 * @description contains offer information
 */
 export class Duration {
    id: number;
    label: string;

    constructor(id: number, label: string) {
        this.id = id;
        this.label = label;
    }

}
