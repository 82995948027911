import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { User } from '@app/_models';
import jwt_decode from "jwt-decode";

@Injectable({ providedIn: 'root' })
export class AccountService {
    private userSubject: BehaviorSubject<User>;
    public user: Observable<User>;


    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
        this.user = this.userSubject.asObservable();
    }

    public get userValue(): User {
        return this.userSubject.value;
    }

    login(username, password) {
        return this.http.post<User>(`${environment.keycloack}/keycloak/token`, { username, password })
            .pipe(map(user => {
                console.log('user : ', user);
                let userConnected = new User();

                const decoded : any = jwt_decode(user.access_token);
                console.log('decoded : ', decoded);
                userConnected.setEmail(decoded.email);
                userConnected.setProfil(
                    decoded.realm_access.roles.includes('admin') ? 'admin' : 'user'
                );
                userConnected.setFirstName(decoded.given_name);
                userConnected.setLastName(decoded.family_name);
                userConnected.setAccessToken(user.access_token);

                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(userConnected));
                this.userSubject.next(userConnected as User);
                return user;
            }));
    }

    logout() {
        // remove user from local storage and set current user to null
        localStorage.removeItem('user');
        this.userSubject.next(null);
        this.router.navigate(['/account/login']);
    }

    register(user: User) {
        return this.http.post(`${environment.keycloack}/user/create`, user);
    }

    assignRole(userId, selectedProfil) {
        return this.http.post(`${environment.keycloack}/user/assign-role/${userId}`, selectedProfil);
    }

    getAll() {
        return this.http.get<User[]>(`${environment.keycloack}/users`);
    }

    getById(id: string) {
        return this.http.get<User>(`${environment.ppApi}/user/${id}`);
    }

    update(id, params) {
        return this.http.put(`${environment.keycloack}/user/update/${id}`, params)
            .pipe(map(x => {
                // update stored user if the logged in user updated their own record
                if (id == this.userValue.userId) {
                    // update local storage
                    const user = { ...this.userValue, ...params };
                    localStorage.setItem('user', JSON.stringify(user));

                    // publish updated user to subscribers
                    this.userSubject.next(user);
                }
                return x;
            }));
    }

    enableDisable(id, params) {
        
        return this.http.put(`${environment.keycloack}/user/enable-disable/${id}`, params)
            .pipe(map(x => {
                // update stored user if the logged in user updated their own record
                if (id == this.userValue.userId) {
                    // update local storage
                    const user = { ...this.userValue, ...params };
                    localStorage.setItem('user', JSON.stringify(user));

                    // publish updated user to subscribers
                    this.userSubject.next(user);
                }
                return x;
            }));
    }

    delete(id: number) {
        return this.http.delete(`${environment.keycloack}/user/delete/${id}`)
            .pipe(map(x => {
                // auto logout if the logged in user deleted their own record
                if (id == this.userValue.userId) {
                    this.logout();
                }
                return x;
            }));
    }
}
