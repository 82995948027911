<div class="row">
    <div class="col-md-12">

        <ul class="nav flex-column menu-list" ngbNav #nav="ngbNav" [(activeId)]="active">
            <!-- Dashboard -->

            <li class="nav-item" [ngClass]="global ? 'active' : ''" ngbNavItem="top"
                (click)="changeActiveMenu('global')">
                <a class="nav-link gva-menu-item " routerLink="dashboard">
                    <img *ngIf="!global"
                        src="/assets/img/Menu/dashboard.svg"
                        alt="dashboard"
                        height="20px"
                        width="30px" />
                    
                    <img *ngIf="global"
                        src="/assets/img/Menu/dashboard-hover.svg"
                        alt="dashboard"
                        height="20px"
                        width="30px" />
                    Global
                </a>
            </li>

            <li class="nav-item" [ngClass]="payment ? 'active' : ''" ngbNavItem="middle"
                (click)="changeActiveMenu('payment')">
                <a class="nav-link gva-menu-item " routerLink="payment">
                    <img *ngIf="!payment"
                        src="/assets/img/Menu/lien-de-paiement.svg"
                        alt="dashboard"
                        height="15px"
                        width="30px" />

                    <img *ngIf="payment"
                        src="/assets/img/Menu/lien-de-paiement-hover.svg"
                        alt="dashboard"
                        height="15px"
                        width="30px" />
                    Lien de paiement
                </a>
            </li>

            <li class="nav-item" [ngClass]="users ? 'active' : ''" ngbNavItem="underbottom" *ngIf="user.profil == 'admin'"
                (click)="changeActiveMenu('users')">
                <a class="nav-link gva-menu-item " routerLink="users">
                    <img *ngIf="!users"
                        src="/assets/img/Menu/gestion-des-utilisateurs.svg"
                        alt="dashboard"
                        height="15px"
                        width="30px" />

                    <img *ngIf="users"
                        src="/assets/img/Menu/gestion-des-utilisateurs-hover.svg"
                        alt="dashboard"
                        height="15px"
                        width="30px" />
                    Gestion des utilisateurs
                </a>
            </li>
        </ul>

        <div class="contact-us-container">
            <div class="contact-us">
                <p>Vous avez besoin d'aide ?</p>
                <button class="orange-button content-shadow btn-padding">
                    <img src="/assets/img/Menu/contactez-nous.svg" alt="dashboard" height="20px" width="30px" />
                    Contactez-nous
                </button>
            </div>
        </div>

    </div>
</div>
