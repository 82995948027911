import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AccountService, AlertService } from '@app/_services';
import { User } from '@app/_models';
import { Router } from '@angular/router';

@Component({
    selector: 'delete-alert',
    templateUrl: './delete.component.html',
    styleUrls: ['./delete.component.css']
})

export class DeleteAlert {

    form: FormGroup;
    loading = false;
    submitted = false;
    @Input() userId: number;
    deleteLoader: boolean = false;
    users: User[];

    constructor(
        private accountService: AccountService,
        private dialog: MatDialog,
        private alertService: AlertService,
        private router: Router
    ) { }

    ngOnInit() {
        this.accountService.getAll().subscribe(
            (users: any[]) => {
                this.users = users;
            }
        );
        // this.accountService.emitPartners();
    }

    dismissDialog() {
        this.dialog.closeAll();
    }

    processDelete() {
        this.deleteLoader = true;
        this.accountService.delete(this.userId)
            .subscribe(() => {
                this.deleteLoader = false;
                this.alertService.success('Success', 'Partenaire supprimé avec succés');
                this.dialog.closeAll();
                location.reload();
                // this.partnerService.emitPartnersAfterDeletd(this.parnerId);
            },
            error => {
                this.deleteLoader = false;
                this.alertService.error('Error', 'Erreur de suppression : ' + error);
                this.dialog.closeAll();
                this.loading = false;
            });
    }
}
