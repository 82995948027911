import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { AccountService, AlertService } from '@app/_services';
import { User } from '@app/_models';
import { MatDialog } from '@angular/material/dialog';
import { DeleteAlert } from './delete/delete.component';
import { ResetPassword } from './reset-password/reset-password.component';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

@Component({ 
    templateUrl: 'list.component.html', 
    styleUrls: ['./list.component.css']
})

export class ListComponent implements OnInit {

    p: number = 1;
    users = null;
    userSize: number;
    term: string;
    currentUser: User[];

    page = 0;
    pageSize = this.users?.length || 0;
    items: number = 10;

    constructor(
        public dialog: MatDialog,
        private accountService: AccountService,
        private alertService: AlertService,
        private http: HttpClient
    ) {}

    ngOnInit() {
        this.accountService.getAll()
            .subscribe(users => {
                console.log('users : ', users);
                this.users = users;
                this.userSize = this.users.length;
            }, error => {
                console.log('error --------------- : ', error);
            });
    }

    onChangeUser(valueChange, id) {
        console.log('valueChange : ', valueChange, id);
        // user.status = valueChange;
        let params = {
            "enabled": `${valueChange}`
        };

        this.accountService.enableDisable(id, params)
            .subscribe({
                next: () => {
                    this.alertService.success("L'utilisateur a été bien modifié", { keepAfterRouteChange: true });
                },
                error: error => {
                    this.alertService.error(error);
                }
            });
    }

    deleteUser(id: number) {
        const dialogRef = this.dialog.open(DeleteAlert);
	    dialogRef.componentInstance.userId = id;
        /* const user = this.users.find(x => x.id === id);
        // user.isDeleting = true;
        this.accountService.delete(id)
            .pipe(first())
            .subscribe(() => {
                this.users = this.users.filter(x => x.userId !== id);
                this.userSize = this.users.length;
                this.alertService.success("L'utilisateur a été supprimé", { keepAfterRouteChange: true });
                // location.reload();
            }); */
    }

    resetPassword(email: string) {
        console.log('email clicked : ', email);
        const dialogRef = this.dialog.open(ResetPassword);
	    dialogRef.componentInstance.email = email;
    }

    getCurretnPageUsers() {
        return this.users.slice(this.page * 10, this.page * 10 + 10);
    }

    getPageSize() {
        return Math.trunc(this.users.length / 10) + 1;
    }

    previousPage() {
        this.page --;
        this.page = this.page < 0 ? 0 : this.page;
        this.currentUser = this.getCurretnPageUsers();
    }

    nextPage() {
        if(this.page < this.getPageSize() - 1) {
            this.page ++;
            this.pageSize = this.getPageSize();
            this.currentUser = this.getCurretnPageUsers();
        }
    }

    onChangeFilter(){
    }

    addItems() {
        this.items ++;
    }

}