import { Subject } from "rxjs";
import { Transaction } from '../_models/transaction';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@environments/environment';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { TransactionUtils } from '@app/utils/transactions.utils';
import { Utils } from "@app/utils/";

import { data } from "../data";

@Injectable({ providedIn: 'root' })
export class TransactionService {

    transactionsSubject = new Subject<Transaction[]>();
    weekRangeSubject = new Subject<any[]>();
    allTransactions: Transaction[];
    loader = false;
    loaderRefresh = false;
    loaderFailedRefresh = false;
    transactionUtils = new TransactionUtils();
    utils = new Utils();

    private transactions = [];
    private weekRange = [];

    constructor(private http: HttpClient) {
        this.weekRange = this.getWeekRange();

        let from = new Date();
		let to = new Date();
		from.setDate(to.getDate() - 90);

        let startDateMonth = (from.getMonth() + 1) <= 9 ? '0' + (from.getMonth() + 1) : (from.getMonth() + 1);
        let endDateMonth = (to.getMonth() + 1) <= 9 ? '0' + (to.getMonth() + 1) : (to.getMonth() + 1);

        let startDateDay = from.getDate() <= 9 ? '0' + from.getDate() : from.getDate();
        let endDateDay = to.getDate() <= 9 ? '0' + to.getDate() : to.getDate();

        let startDate = from.getFullYear() + '-' + startDateMonth + '-' + startDateDay;
        let endDate = to.getFullYear() + '-' + endDateMonth + '-' + endDateDay;

        this.fetchByDateInterval(startDate, endDate, '').subscribe(transactions => {
            transactions.forEach(element => {
                element.forEach(element => {
                    this.transactions.push(this.transactionUtils.rawDataTOTransaction(element));
                });
            });
            this.transactions = this.allTransactions = this.utils.sortByDate(this.transactions);
            this.emitTransactions();
        });
    }

    fetchByDateInterval(start: string, end: string, partner: string) {
        // let type = this.selectedService == 'mobile-transfer' ? 'wallet' : 'bulk';
        const requestOptions = {
            headers: new HttpHeaders({
                // 'Service-Provider': partner ? partner : '',
                'Start-Date': start,
                'End-Date': end
                // 'Type': 'wallet'
            }),
        };

        return this.http.get<any[]>(`${environment.keycloack}/mobilemoney-reporting`, requestOptions);
    }

    emitTransactions() {
        this.transactionsSubject.next(this.transactions.slice());
    }

    emitWeekRange() {
        this.weekRangeSubject.next(this.weekRange.slice());
    }

    getAll() {
        return this.allTransactions;
    }

    fetchAll() {
        return this.http.get<Transaction[]>(`${environment.ppApi}/transaction`);
    }

    getWeekRange() {
        let curr = new Date;
        let first = curr.getDate() - curr.getDay();
        let last = first + 6;

        let firstday = new Date(curr.setDate(first));
        let lastday = new Date(curr.setDate(last));

        return [{
            firstday,
            lastday
        }];
    }

    setWeekRange(weekRange) {
        console.log('weekRange : ', weekRange);
        this.transactions = [];

        this.weekRange = [
            {
                firstday: weekRange.firstday,
                lastday: weekRange.lastday
            }
        ];

        let startDateMonth = weekRange.firstday.month <= 9 ? '0' + weekRange.firstday.month : weekRange.firstday.month;
        let endDateMonth = weekRange.lastday.month <= 9 ? '0' + weekRange.lastday.month : weekRange.lastday.month;

        let startDateDay = weekRange.firstday.day <= 9 ? '0' + weekRange.firstday.day : weekRange.firstday.day;
        let endDateDay = weekRange.lastday.day <= 9 ? '0' + weekRange.lastday.day : weekRange.lastday.day;

        let startDate = weekRange.firstday.year + '-' + startDateMonth + '-' + startDateDay;
        let endDate = weekRange.lastday.year + '-' + endDateMonth + '-' + endDateDay;

        this.fetchByDateInterval(startDate, endDate, '').subscribe(transactions => {
            transactions.forEach(element => {
                element.forEach(element => {
                    this.transactions.push(this.transactionUtils.rawDataTOTransaction(element));
                });
            });
            this.transactions = this.allTransactions = this.utils.sortByDate(this.transactions);
            this.emitTransactions();
            this.emitWeekRange();
        });
    }

    applyFilter(weekRange) {

        let transactions: Transaction[] = null;

        if (weekRange.country && weekRange.country.length) {
            transactions = this.filterByCountry(weekRange.country, this.allTransactions);
        } else {
            transactions = this.allTransactions;
        }
        
        if (weekRange.payment && weekRange.payment.length > 0) {
            transactions =  this.filterByPayment(weekRange.payment, transactions);
        } else if(transactions == null) {
            transactions = this.allTransactions;
        }

        if (weekRange.operatorFilter && weekRange.operatorFilter.length > 0) {
            transactions =  this.filterByOperator(weekRange.operatorFilter, transactions);
        } else if(transactions == null) {
            transactions = this.allTransactions;
        }

        this.transactions = this.utils.sortByDate(transactions);
        this.emitTransactions();
        this.emitWeekRange();
    }

    setWeekRangeFromTab(weekRange) {
        this.weekRange = [
            {
                firstday: weekRange.firstday,
                lastday: weekRange.lastday
            }
        ];

        this.transactions =  this.filterTransactionsByDate(this.allTransactions);
        this.emitTransactions();
        this.emitWeekRange();
    }

    setAllData() {
        console.log('this.transactions : ', this.transactions);
        this.transactions = this.allTransactions;
        this.emitTransactions();
        this.emitWeekRange();
    }

    updateTransactions(transactions: Transaction[], type: string) {
        const nbrTransaction = transactions.length;
        let cmpTrsanction = 0;
        type == 'failed' ? this.loaderFailedRefresh = true : this.loaderRefresh = true;
        transactions.forEach((transaction, index) => {
            if (transaction.mnoName) {
                const params = {
                    "country": transaction.fromCountryCode,
                    "mno": transaction.mnoName,
                    "orderId": transaction.merchantReference,
                    "lang": "fr",
                    "channel": "web" 
                };
                this.http.post<any[]>(`${environment.updateStatusMMUrl}`, params).pipe(first())
                .subscribe({
                    next: (data: any) => {
                        cmpTrsanction ++;
                        this.updateRefreshLoader(nbrTransaction, cmpTrsanction);
                    },
                    error: error => {
                        cmpTrsanction ++;
                        this.updateRefreshLoader(nbrTransaction, cmpTrsanction);
                    }
                });
            } else {
                const params = {
                    "country": transaction.fromCountryCode,
                    "orderId": transaction.merchantReference,
                };
                this.http.post<any[]>(`${environment.updateStatusCardUrl}`, params).pipe(first())
                .subscribe({
                    next: (data: any) => {
                        cmpTrsanction ++;
                        this.updateRefreshLoader(nbrTransaction, cmpTrsanction);
                    },
                    error: error => {
                        cmpTrsanction ++;
                        this.updateRefreshLoader(nbrTransaction, cmpTrsanction);
                    }
                });
            }
        });
    }

    updateRefreshLoader(nbrTransaction, cmpTransaction) {
        if (nbrTransaction == cmpTransaction) {
            this.loaderRefresh = false;
            this.loaderFailedRefresh = false;
            this.fetchAll().subscribe(transactions => {
                this.transactions = transactions;
                this.allTransactions = transactions;
                this.emitTransactions();
                this.emitWeekRange();
                this.loader = true;
            });
        }
    }

    filterByCountry(country, transactions) {
        return transactions.filter(transaction => transaction.countryCode == country);
    }

    filterByPayment(payment, transactions) {
        return transactions.filter(transaction => transaction.payment == payment);
    }

    filterByOperator(operator, transactions) {
        return transactions.filter(transaction => transaction.mnoName == operator);
    }

    filterTransactionsByDate(transactions) {
        return transactions.filter(transaction => {
            let transactionDate = new Date(transaction.createdAt);
            let transactionNgDate = new NgbDate(transactionDate.getFullYear(), transactionDate.getMonth() + 1, transactionDate.getDate());

            let testDate = (transactionNgDate.after(this.weekRange[0].firstday) || transactionNgDate.equals(this.weekRange[0].firstday)) && 
            (transactionNgDate.before(this.weekRange[0].lastday) || transactionNgDate.equals(this.weekRange[0].lastday));

            return testDate ? transaction : false;
        });
    }
}
