import { Component, OnInit, Injectable } from '@angular/core';
import { faDatabase } from '@fortawesome/free-solid-svg-icons';
import { Transaction } from '@app/_models/transaction';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { TransactionService, AlertService } from '@app/_services/';
import { saveAs } from 'file-saver';

import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';


@Component({
	selector: 'app-transaction-table',
	templateUrl: './transaction-table.component.html',
	styleUrls: ['./transaction-table.component.css']
})

@Injectable()
export class TransactionTableComponent implements OnInit {

	p: number = 1;
	transactions = null;
	allTransactions = null;
	currentTransactionsTable: Transaction[];
    term: string;

	closeResult = '';
	transactionSubscription: Subscription;
	refreshTransaction: Subscription;
	loader = false;
	currentTransaction: Transaction[];

	transactionsToExport = [];

	page = 0;
	pageSize = this.transactions?.length || 0;

	databaseIcon = faDatabase;

	headElements = ['#', 'Date', 'ReferenceID', 'PNR', 'Nom', 'Prénom', 'Numéro', 'Pays', 'Moyen de paiement', 'Opérateur', 'Montant', 'Status', 'Action'];

	all : boolean = true;
	success : boolean = false;
	fail : boolean = false;
	abandonned : boolean = false;

	constructor(
		private modalService: NgbModal,
		public transactionService: TransactionService,
		private alertService: AlertService) {
	}

	ngOnInit(): void {
		this.transactionSubscription = this.transactionService.transactionsSubject.subscribe(
			(transactions: any[]) => {
				this.transactions = transactions;
				this.allTransactions = transactions;
				this.pageSize = this.getPageSize();
				// this.currentTransactionsTable = this.getCurretnPageTransactions();
			}
		);
		this.transactionService.emitTransactions();
	}

	getLoader() {
		return this.transactionService.loader;
	}

	getLoaderRefresh() {
		return this.transactionService.loaderRefresh;
	}

	getFailedLoader() {
		return this.transactionService.loaderFailedRefresh;
	}

	open(content, id) {
		this.currentTransaction = this.transactions.filter(transaction => transaction.id == id);

		this.modalService.open(content, { size: 'lg', ariaLabelledBy: 'modal-basic-title' }).result.then(result => {
			this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

	downloadCsv() {
		const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
		const header = Object.keys(this.transactions[0]);
		let csv = this.transactions.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
		csv.unshift(header.join(','));
		let csvArray = csv.join('\r\n');

		let blob = new Blob([csvArray], { type: 'text/csv' })
		saveAs(blob, "transactions.csv");
	}

	downloadExcel() {

		this.transactions.forEach(transaction => {
			console.log('transaction : ', transaction);
			let duration = '';
			let offer = '';
			if (transaction.package) {
				if (transaction.package.duration) {
					duration = transaction.package.duration.label;
				}
				if (transaction.package.offer) {
					offer = transaction.package.offer.label;
				}
			}

			this.transactionsToExport.push({
				'PartnerID': transaction.orderId,
				'ReferenceID': transaction.orderId,
				'Statut': transaction.status,
				'Nom': transaction.user ? transaction.user.lastName : '-',
				'Prénom': transaction.user ? transaction.user.firstName : '-',
				'Téléphone': transaction.phone,
				'Email': transaction.email,
				'TransDate': transaction.createdAt,
				'Méthode de paiement': transaction.mnoName || 'Bank',
				'Opérateur': transaction.mnoName || 'Bank',
				'Pays': transaction.country,
				'Devise': transaction.currency,
				'Amount': transaction.amount
			});
		});

		this.exportAsExcelFile(this.transactionsToExport, 'export-excel');
	}

	public exportAsExcelFile(json: any[], excelFileName: string): void {
		const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.transactionsToExport);
		const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
		const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
		this.saveAsExcelFile(excelBuffer, excelFileName);
	}

	private saveAsExcelFile(buffer: any, fileName: string): void {
		const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
		saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
	}

	getCurretnPageTransactions() {
		return this.transactions.slice(this.page * 10, this.page * 10 + 10);
	}

	getPageSize() {
		return Math.trunc(this.transactions.length / 10) + 1;
	}

	previousPage() {
		this.page--;
		this.page = this.page < 0 ? 0 : this.page;
		this.currentTransactionsTable = this.getCurretnPageTransactions();
	}

	nextPage() {
		if (this.page < this.getPageSize() - 1) {
			this.page++;
			this.pageSize = this.getPageSize();
			this.currentTransactionsTable = this.getCurretnPageTransactions();
		}
	}

	refrechTransactionStatus() {
		const transactionsInProgress = this.transactions.filter(transaction => transaction.status === "inprogress");

		if (transactionsInProgress.length > 0) {
			this.transactionService.updateTransactions(transactionsInProgress, 'inprogress')
		} else {
			this.alertService.success("Aucune transaction à traiter");
		}
	}

	refrechFailedTransaction() {
		const transactionsFailed = this.transactions.filter(transaction => transaction.status === "failed");

		if (transactionsFailed.length === 1) {
			this.transactionService.updateTransactions(transactionsFailed, 'failed')
		} else {
			this.alertService.success("Il faut filtrer par référence ID");
		}
	}

	filterTransactionsByStatus(status: string) {
		if(status == 'all') {
			this.transactions = this.allTransactions;
		} else {
			this.transactions = this.allTransactions.filter(transaction => transaction.status == status);
		}
	}

	changeSelectedRange(range: string) {
		
		if(range == 'all') {
			this.all = true;
			this.success = false;
			this.fail = false;
			this.abandonned = false;

			this.filterTransactionsByStatus('all');
		} else if (range == 'success') {
			this.all = false;
			this.success = true;
			this.fail = false;
			this.abandonned = false;

			this.filterTransactionsByStatus('Successful');
		} else if (range == 'fail') {
			this.all = false;
			this.success = false;
			this.fail = true;
			this.abandonned = false;

			this.filterTransactionsByStatus('Failed');
		} else {
			this.all = false;
			this.success = false;
			this.fail = false;
			this.abandonned = true;

			this.filterTransactionsByStatus('Abandonned');
			// this.filterByLastDays(90);
		}
	}

}
