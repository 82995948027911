<div class="col-md-12 card-amount none-padding">
    <img src="../../../../assets/img/Dashboard/montant-collecte.svg" 
        *ngIf="showIcon()"/>
    <h5 class="title">
        {{ title }}
        <span class="badge badge-success" *ngIf="isSuccess()">
            {{ pourcentage | number:'2.1-2':'fr' }} %
        </span>
        <span class="badge badge-danger" *ngIf="isFails()">
            {{ pourcentage | number:'2.1-2':'fr' }} %
        </span>
    </h5>

    <h2 class="amount">
        <span *ngIf="!isMoney()">
            {{ amount }}
        </span>

        <span *ngIf="isMoney()">
            {{ amount | currency:'XOF':'symbol':'4.2-2':'fr'}}
        </span>
    </h2>

    <span class="description">
        {{ description }}
    </span>

</div>
