<div class="col-md-12 breadcrumbs-block">
    <div class="row">
        
        <div class="col-md-6">
            <strong>App</strong> <span class="breadcrumbs-color"> > Configuration > Gestion des utilisateurs</span>
        </div>

        <div class="col-md-6">
        </div>
    </div>
</div>

<div class="col-md-12 shadow-sm bg-white rounded content-shadow none-padding" style="float: left;">

    <div class="col-md-12 border-bottom block-header padding float-left">
        <h5 (click)='addItems()'>
            Gestions des utilisateurs 
            <span class="badge badge-warning orange-background offers-number text-white">
                {{ userSize }}
            </span>
        </h5>

        <a class="orange-button float-right content-shadow" routerLink="add"
            style="text-decoration: none;">
            <img src="/assets/img/icons/add-offre.svg" alt="Logo Bizao" width="15px" style="margin-right: 10px;"/>
            Ajouter un utilisateur
        </a>

        <!-- <a class="white-button float-right mr-15 content-shadow">
            <img src="/assets/img/icons/filter.svg" alt="Logo Bizao" width="15px"/>
            Filtre
        </a> -->
    </div>

    <div class="col-md-12 block-header padding float-left">
        <div class="row">
            <div class="col-md-3">
                <img src="/assets/img/icons/Dashboard/recherche.svg" alt="Logo Bizao" width="15px" 
                    class="search-icon"/>
                <input name="datepicker" type="text" class="form-control search-form border-radius-search" 
                    [(ngModel)]="term" placeholder="Rechercher" (keyup)="onChangeFilter()" />
            </div>

            <div class="green-button bg-green float-right col-md-3">

            </div>
        </div>
    </div>

    <div class="padding">
        <table class="table border-bottom">
            <thead>
                <tr>
                    <th>#</th>
                    <th>Prénom</th>
                    <th>Nom</th>
                    <th>E-mail</th>
                    <th>Adresse</th>
                    <th>Profil</th>
                    <th>Téléphione</th>
                    <th>Etat</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let user of users|filter:term | paginate: { itemsPerPage: items, currentPage: p }; let i = index;">

                    <th>{{ (i + 1) + (page * 10) }}</th>
                    <td>{{ user.firstName }}</td>
                    <td>{{ user.lastName }}</td>
                    <td>{{ user.emailAddress }}</td>
                    <td>{{ user.address }}</td>
                    <td>{{ user.profile ? user.profile : '-' }}</td>
                    <td>{{ user.phoneNumber }}</td>

                    <td>
                        <ui-switch [(ngModel)]="user.enabled"
                            (valueChange)="onChangeUser($event, user.userId)"></ui-switch>
                    </td>
    
                    <td style="white-space: nowrap">
                        <div class="d-inline-block" ngbDropdown #myDrop="ngbDropdown" class="block-action">
                            <!-- <button class="action-button mr-2" id="dropdownManual" 
                                ngbDropdownAnchor (focus)="myDrop.open()">°°°</button> -->

                            <div class="action-button">
                                <a routerLink="edit/{{user.userId}}" class="">
                                    <button class="action-button mr-2 edit-button" id="dropdownManual" 
                                        ngbDropdownAnchor (focus)="myDrop.open()">
                                        
                                    </button>
                                </a>
                            </div>

                            <div class="action-button">
                                <button class="action-button mr-2 delete-button" (click)="deleteUser(user.userId)">
                                    
                                </button>
                            </div>

                            <div class="action-button">
                                <button class="action-button mr-2 reset-button" (click)="resetPassword(user.emailAddress)">
                                    
                                </button>
                            </div>
    
                            <!-- <div ngbDropdownMenu aria-labelledby="dropdownManual" class="dropdown-action">
                                <a routerLink="edit/{{user.userId}}" 
                                    class="btn btn-sm btn-primary mr-1 btn-modifier">Edit</a>
                                
                                <button (click)="deleteUser(user.userId)"
                                    class="btn btn-sm btn-danger btn-delete-user btn-modifier" 
                                    [disabled]="user.isDeleting">
                                    <span *ngIf="user.isDeleting" class="spinner-border spinner-border-sm"></span>
                                    <span *ngIf="!user.isDeleting">Delete</span>
                                </button>
                            </div> -->
                        </div>
    
                    </td>
                </tr>
                <tr *ngIf="!users">
                    <td colspan="10" class="text-center">
                        <span class="spinner-border spinner-border-lg align-center"></span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="container-fluid">

        <div class="row">
            <div class="col-lg-6">
                Affichage de 1 à 4 sur 200 
                <select class="select-items-per-page">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                </select>
            </div>
    
            <div class="col-lg-6">
                <pagination-controls
                    (pageChange)="p = $event"
                    responsive="true"
                    previousLabel="<"
                    nextLabel=">" class="float-right"></pagination-controls>
            </div>
        </div>
        

        <!-- <button class="content-shadow white-button float-right" (click)="nextPage()">
            Suivant
        </button>

        <button class="content-shadow white-button float-right mr-15" (click)="previousPage()">
            Précédent
        </button>

        <div class="float-right mr-25 gva-table-pagination">
            Pages <strong>{{ page + 1 }}</strong> sur <strong>{{ pageSize == 0 ? 1 : pageSize }}</strong>
        </div> -->
        
    </div>

</div>
