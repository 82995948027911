import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Duration } from '@app/_models/';

@Injectable({ providedIn: 'root' })
export class DurationService {

    private durationSubject: BehaviorSubject<Duration>;
    public duration: Observable<Duration>;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.durationSubject = new BehaviorSubject<Duration>(new Duration(0, ''));
        this.duration = this.durationSubject.asObservable();
    }

    public get durationValue(): Duration {
        return this.durationSubject.value;
    }

    add(duration: Duration) {
        return this.http.post(`${environment.ppApi}/duration/`, duration);
    }

    getAll() {
        return this.http.get<Duration[]>(`${environment.ppApi}/duration`);
    }

    getById(id: number) {
        return this.http.get<Duration>(`${environment.ppApi}/duration/${id}`);
    }

    update(id, params) {
        return this.http.put(`${environment.ppApi}/duration/${id}`, params)
            .pipe(map(x => {
                return x;
            }));
    }

    delete(id: number) {
        return this.http.delete(`${environment.ppApi}/duration/${id}`)
            .pipe(map(x => {
                return x;
            }));
    }
}
