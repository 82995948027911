// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/* export const baseAPI = '#';
export const paiementLink = 'https://onlinepay.bizao.com/gva/';
export const ppApi = "https://pprd-onlinepay-api-gateway.bizao.com/api/";
export const environment = {
	production: false,
	apiUrl: 'http://localhost:4000',
	ppApi: "https://pprd-onlinepay-api-gateway.bizao.com/api"
}; */

// Prod
export const paiementLink = 'https://onlinepay.bizao.com/gva/';
export const baseAPI = '#';
export const environment = {
	production: false,
	apiUrl: 'http://localhost:4000',
	ppApi: "https://onlinepay-api-gateway.bizao.com/api",
	updateStatusMMUrl: "https://onlinepay-api-gateway.bizao.com/api/transaction/status",
	updateStatusCardUrl: "https://onlinepay-api-gateway.bizao.com/api/transaction/card/status",
	keycloack: "https://mistral-backend-app.herokuapp.com/api",
	dashboardApi: 'https://bsc-api-gateway.herokuapp.com/api',
	link: 'https://mistral-user-portal.bizao.com/sumary/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
