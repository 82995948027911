import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DurationService, FormuleService, OfferService, AlertService } from '@app/_services';
import { first } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-add',
    templateUrl: './add.component.html',
    styleUrls: ['./add.component.css']
})

export class AddComponent implements OnInit {

    durations = null;
    formules = null;
    form: FormGroup;
    loading = false;
    submitted = false;
    isAddMode: boolean;
    id: string;

    idFormule: string = null;
    idDuration: string = null;

    constructor(
        private formBuilder: FormBuilder,
        private durationService: DurationService,
        private formuleService: FormuleService,
        private offerService: OfferService,
        private router: Router,
        private route: ActivatedRoute,
        private alertService: AlertService
    ) { }

    get f() { return this.form.controls; }

    ngOnInit(): void {
        this.id = this.route.snapshot.params['id'];
        this.isAddMode = !this.id;

        this.form = this.formBuilder.group({
            offer:        ['', Validators.required],
            duration:     ['', Validators.required],
            price:        ['', Validators.required],
            description:  []
        });

        if (!this.isAddMode) {
            this.offerService.getById(this.id)
                .pipe(first())
                .subscribe(x => {
                this.form.patchValue({
                    offer: x.offer.id,
                    duration: x.duration.id,
                    price: x.price,
                    description: x.offer.description
                });
                this.initData();
            });
        } else  {
            this.initData();
        }
    }

    initData() {
        forkJoin({
            durations: this.durationService.getAll().pipe(first()),
            formules: this.formuleService.getAll().pipe(first()),
        }).subscribe(({durations, formules}) => {
            this.durations = durations;
            this.formules = formules;
        });
    }

    onSubmit() {
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }

        this.loading = true;

        this.loading = true;
        if (this.isAddMode) {
            this.createOffer();
        } else {
            this.updateOffer();
        }
    }

    getSelectedFormule() {
        return this.formules.filter(formule => formule.id === this.form.value.formule);
    }

    getSelectedDuration() {
        return this.durations.filter(duration => duration.id === this.form.value.duration);
    }

    createOffer(){
        this.offerService.add(this.form.value)
        .pipe(first())
        .subscribe({
            next: (data: any) => {
                if(data.error && data.error === true) {
                    this.alertService.error("Erreur d'ajout d'offre : " + data.message, { keepAfterRouteChange: true });
                } else {
                    this.alertService.success('Offer ajoutée avec success', { keepAfterRouteChange: true });
                    this.router.navigate(['../'], { relativeTo: this.route });
                }
            },
            error: error => {
                this.alertService.error(error);
                this.loading = false;
            }
        });
    }

    updateOffer() {
        this.offerService.update(this.id, this.form.value)
        .pipe(first())
        .subscribe({
            next: (data: any) => {
                if(data.error && data.error === true) {
                    this.alertService.error("Erreur de modification d'offre : " + data.message, { keepAfterRouteChange: true });
                } else {
                    this.alertService.success('Offre mis à jour', { keepAfterRouteChange: true });
                    this.router.navigate(['../../'], { relativeTo: this.route });
                }
            },
            error: error => {
                this.alertService.error(error);
                this.loading = false;
            }
        });
    }

    reorderDurationsBy(durations) {
        /* durations.forEach(duration => {
            let mounthValue = duration.label.replace(' mois', '');
            if () {

            }
        }); */
    }

}
