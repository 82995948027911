<div class="col-lg-12 col-md-12 col-sm-12 none-padding">
    <div class="row filter">

        <div class="col-lg-9 col-md-9 col-sm-12 ">

            <div class="form-filter-block">
                <form class="">
                    <div class="form-group hidden">
                        <div class="input-group">
                            <input name="datepicker" class="form-control date-picker" ngbDatepicker
                                #datepicker="ngbDatepicker" [autoClose]="'outside'"
                                (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t"
                                outsideDays="hidden" [startDate]="fromDate!" tabindex="-1">

                            <ng-template #t let-date let-focused="focused">
                                <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
                                    [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                                    (mouseleave)="hoveredDate = null">
                                    {{ date.day }}
                                </span>
                            </ng-template>
                        </div>
                    </div>

                    <div class="form-group contain-form-filter-date content-shadow border-radius-left
                        border-radius-right">
                        <div class="input-group input-form-date-picker">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary btn-calendar border-radius-left"
                                    (click)="datepicker.toggle()" type="button">
                                    <img _ngcontent-deq-c178="" src="/assets/img/Dashboard/calendrier.svg"
                                        alt="dashboard" height="20px" width="30px">
                                </button>
                            </div>

                            <input #dpFromDate class="form-control chose-date-field none-padding start-date"
                                placeholder="yyyy-mm-dd" name="dpFromDate" [value]="formatter.format(fromDate)"
                                (input)="fromDate = validateInput(fromDate, dpFromDate.value)">

                            <input #dpToDate
                                class="form-control chose-date-field none-padding end-date border-radius-right"
                                placeholder="yyyy-mm-dd" name="dpToDate" [value]="formatter.format(toDate)"
                                (input)="toDate = validateInput(toDate, dpToDate.value)">

                        </div>
                    </div>

                    <!-- <div class="input-group-append contain-form-filter-button">
                        <button class="btn btn-outline-secondary orange-button" type="button"
                            (click)="onSubmit()">
                            Filtrer
                        </button>
                    </div> -->
                </form>
            </div>


            <div ngbDropdown class="d-inline-block ">
                <a class="white-button float-right mr-15 content-shadow white-background" id="dropdownBasic1"
                    ngbDropdownToggle>
                    <img src="/assets/img/Dashboard/filtre.svg" alt="Logo Bizao" width="15px" />
                    Filtre avancé
                </a>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="content-shadow"
                    style="padding: 10px !important;">

                    <div class="filter-line">
                        <div>
                            <strong>
                                Pays
                            </strong>
                        </div>
                        <div>
                            <select class="form-control" id="country" name="country" [(ngModel)]="country">

                                <option value="ci">Cote d'Ivoire</option>
                                <option value="sn">Sénégal</option>

                            </select>

                            <!-- <input type="text" class="form-control input-search" id="country" class="form-control"
                                [(ngModel)]="country" placeholder="Pays"> -->
                        </div>
                    </div>

                    <div class="filter-line">
                        <div>
                            <strong>
                                Mode de paiement
                            </strong>
                        </div>
                        <div>
                            <select class="form-control" id="payment" name="payment" [(ngModel)]="payment">

                                <option value="mobile-money">Mobile money</option>
                                <option value="money-transfer">Transfert d'argent</option>

                            </select>

                            <!-- <input type="text" class="form-control input-search" id="payment" class="form-control"
                                [(ngModel)]="payment" placeholder="Mode de paiement"> -->
                        </div>
                    </div>

                    <div class="filter-line">
                        <div>
                            <strong>
                                Opérateur
                            </strong>
                        </div>
                        <div>
                            <select class="form-control" id="operatorFilter" name="operatorFilter" [(ngModel)]="operatorFilter">

                                <option value="moov">Moov</option>
                                <option value="orange">Orange</option>
                                <option value="mtn">MTN</option>

                            </select>
                            <!-- <input type="text" class="form-control input-search" id="operatorFilter"
                                class="form-control" [(ngModel)]="operatorFilter" placeholder="Opérateur"> -->
                        </div>
                    </div>

                    <div class="filter-line">
                        <button class="shadow-sm green-button bg-green float-right content-shadow"
                            (click)='applyFilter()'>
                            Appliquer
                        </button>

                        <button class="shadow-sm bg-white white-button float-right content-shadow">
                            Annuler
                        </button>
                    </div>

                    <!-- <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0">

                        <ngb-panel title="Numéro de router">
                            <ng-template ngbPanelContent>
                                <div class="form-group row">
                                    <div class="col-sm-10">
                                        <input type="text" class="form-control input-search" id="routerId"
                                            class="form-control" [(ngModel)]="routerId" placeholder="Numéro de router">
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-panel>

                        <ngb-panel title="Référence ID">
                            <ng-template ngbPanelContent>
                                <div class="form-group row">
                                    <div class="col-sm-10">
                                        <input type="text" class="form-control" id="referenceId" class="form-control"
                                            [(ngModel)]="referenceNumber" placeholder="Entrer le référence ID">
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-panel>

                        <ngb-panel title="E-mail">
                            <ng-template ngbPanelContent>
                                <div class="form-group row">
                                    <div class="col-sm-10">
                                        <input type="email" class="form-control" id="email" class="form-control"
                                            [(ngModel)]="email" placeholder="Entrer l'E-mail">
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-panel>

                        <ngb-panel title="Numéro de téléphone">
                            <ng-template ngbPanelContent>
                                <div class="form-group row">
                                    <div class="col-sm-10">
                                        <input type="text" class="form-control" id="num-tel" class="form-control"
                                            [(ngModel)]="numTel" placeholder="Entrer le numéro de téléphone">
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-panel>

                    </ngb-accordion> -->
                </div>
            </div>

        </div>

        <div class="col-lg-2 col-md-2 col-sm-12">

        </div>

    </div>
</div>
