import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { Payment } from '@app/_models';

@Injectable({ providedIn: 'root' })
export class PaymentService {

    private paymentSubject: BehaviorSubject<Payment>;
    public payment: Observable<Payment>;

    constructor(
        private http: HttpClient
    ) {
        this.paymentSubject = new BehaviorSubject<Payment>(new Payment(''));
        this.payment = this.paymentSubject.asObservable();
    }

    public get paymentValue(): Payment {
        return this.paymentSubject.value;
    }

    createPaymentLink(payment: {}) {
        return this.http.post(`${environment.keycloack}/link`, payment);
    }

    generateReference() {
        return this.http.get<any>(`${environment.keycloack}/link/reference`);
    }

    sendLinkViaMail(payment: Payment) {
        return this.http.post(`${environment.ppApi}/link/send`, payment);
    }
}
