import { Component, OnInit, AfterViewInit } from '@angular/core';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { first } from 'rxjs/operators';

import { OfferService, AlertService } from '@app/_services';
import { Offer } from '@app/_models';

@Component({
	selector: 'app-offers',
	templateUrl: './offers.component.html',
	styleUrls: ['./offers.component.css']
})

export class OffersComponent implements OnInit, AfterViewInit {

	faPlus = faPlus;
	offers = null;
	currentOffers: Offer[];

	isDeleting: null;
	offerSize: 0;

	page = 0;
	pageSize = this.offers?.length || 0;

	headElements = ['#', 'Formule', 'Durée', 'Montant', 'Description', 'Statut', 'Action'];

	constructor(
		private offerService: OfferService,
		private alertService: AlertService
	) { }

	ngOnInit(): void { }

	ngAfterViewInit() {
		this.offerService.getAll()
			.pipe(first())
			.subscribe(offers => {
				this.offers = offers;
				this.offerSize = this.offers.length;
			});
	}

	onChangeOffer(valueChange, id) {
		const offer = this.offers.find(x => x.id === id);
		offer.status = valueChange;
		this.offerService.update(offer.id, offer)
			.pipe(first())
			.subscribe({
				next: () => {
					this.alertService.success("L'offre a été bien modifié", { keepAfterRouteChange: true });
				},
				error: error => {
					this.alertService.error(error);
				}
			});
	}

	deleteOffer(id: number) {
		const offer = this.offers.find(x => x.id === id);
		offer.isDeleting = true;
		this.offerService.delete(id)
			.pipe(first())
			.subscribe(() => {
				this.offers = this.offers.filter(x => x.id !== id);
				this.offerSize = this.offers.length;
			});
	}

	getPageSize() {
		return Math.trunc(this.offers.length / 10) + 1;
	}

	previousPage() {
		this.page--;
		this.page = this.page < 0 ? 0 : this.page;
		this.currentOffers = this.getCurretnPageTransactions();
	}

	nextPage() {
		if (this.page < this.getPageSize() - 1) {
			this.page++;
			this.pageSize = this.getPageSize();
			this.currentOffers = this.getCurretnPageTransactions();
		}
	}

	getCurretnPageTransactions() {
		return this.offers.slice(this.page * 10, this.page * 10 + 10);
	}

}
