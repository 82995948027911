<div class="col-md-12 padding">

    <div class="row">

        <div class="col-md-12 none-padding">
            <strong>App</strong> > <span class="breadcrumbs-color"> Dashboard </span>
        </div>

        <div class="col-md-12 none-padding">
            <div class="container-fild">
                <app-filter></app-filter>
            </div>
        </div>

        <div class="col-md-12 shadow-sm rounded">
            <div class="row">

                <div class="col-md-7 padding none-top-left-padding">
                    <div class="col-md-12 border-right padding bg-white content-shadow">
                        <!-- <div *ngIf="!getLoader()">
                            <span class="spinner-border spinner-border-lg align-center"></span>
                        </div> -->
                        <app-chart ></app-chart>
                        <div class="row">
                            <span class="text-legend">
                                <span class="legend success-legend"></span>
                                Transactions à succés
                            </span>

                            <span class="text-legend">
                                <span class="legend echec-legend"></span>
                                Transactions à échec
                            </span>

                            <span class="text-legend">
                                <span class="legend abandonned-legend"></span>
                                Transactions abandonnées
                            </span>
                        </div>
                    </div>
                </div>

                <div class="col-md-5 padding none-top-right-padding content-shadow bg-white">
                    <div class="col-md-12 border-right">
                        <div class="row">
                            <app-amount-card class="col-md-12 border-bottom text-center"
                                [amount]="totalSucccessRevene"
                                title="Montant collecté"
                                badge="none"
                                description="Montant total des transactions à succés"
                                currency="XOF"
                                icon='true'></app-amount-card>

                            <app-amount-card class="col-md-4 border-right"
                                [amount]="totalSuccessTransactions" 
                                title="Success" 
                                badge="success"
                                description="Total du trafic (success)"
                                currency="no"
                                [pourcentage]="successPourcentage"
                                icon='false'></app-amount-card>

                            <app-amount-card class="col-md-4 border-right"
                                [amount]="totalFailedTransactions"
                                title="Fails"
                                badge="failed"
                                description="Total du trafic (fails)"
                                currency="no"
                                [pourcentage]="failsPourcentage"
                                icon='false'></app-amount-card>
                            
                            <app-amount-card class="col-md-4"
                                [amount]="totalAbandonnedTransactions"
                                title="Abandonned"
                                badge="abandonned"
                                description="Total du trafic (Abandonned)"
                                currency="no"
                                [pourcentage]="abandonnedPourcentage"
                                icon='false'></app-amount-card>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="col-md-12 none-padding shadow-sm bg-white rounded transaction-table content-shadow">
            <div class="col-lg-12">

                <app-transaction-table ></app-transaction-table>

            </div>
        </div>

    </div>

</div>
