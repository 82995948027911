<div class="col-md-12 shadow-sm p-3 bg-white rounded content-shadow header">
    <div class="row">
        <div class="col-md-5">
            <img src="/assets/img/logo-mistral-voyage.svg" alt="Logo Bizao" width="150px"/>
        </div>

        <div class="col-md-6">

            <div class="col">
                <div ngbDropdown class="d-inline-block header-dropdown">
                    <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>
                        <span class="image-span">
                            <img src="/assets/img/icons/account.svg" alt="Logo Bizao" width="15px"/>
                        </span>
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="action-list-header"
                        style="padding: 0px !important;">
                        <span class="nav-item nav-link" href="#">Utilisateur</span>
                        <span class="nav-item nav-link" href="#">{{ user.firstName }}</span>
                        <hr>
                        <a class="nav-item nav-link" href="#">Profil</a>
                        <a class="nav-item nav-link" (click)="logout()">Déconexion</a>
                    </div>
                </div>
            </div>

            <!-- <nav class="navbar navbar-expand text-right" *ngIf="user">
                <div class="navbar-nav">
                    <a class="nav-item nav-link" (click)="logout()">Logout</a>
                    
                </div>
            </nav> -->

        </div>

    </div>
</div>
