<div class="col-md-12 none-padding">

    <div class="col-md-12 border-bottom block-header padding">

        <div class="row">

            <div class="col-md-5 padding">
                <h5>
                    Détails des transactions
                </h5>
            </div>

            <div class="col-md-7 padding float-rigth">
                <div class="row">
                    <div class="col-md-4">
                        <!-- <input name="datepicker" type="text" class="form-control search-form" placeholder="Rechercher" /> -->
                        <img src="/assets/img/icons/Dashboard/recherche.svg" alt="Logo Bizao" width="15px" 
                            class="search-icon"/>

                        <input name="search" type="text" class="form-control search-form border-radius-search" 
                            [(ngModel)]="term" placeholder="Rechercher" />
                    </div>

                    <button class="shadow-sm green-button bg-green float-right content-shadow col-md-3 none-padding">
                        <img _ngcontent-agq-c177="" src="/assets/img/Dashboard/actualise.svg" alt="dashboard" height="15px"
                            width="30px">
                        Rafraichir
                    </button>

                    <button class="shadow-sm bg-white white-button float-right content-shadow col-md-3 none-padding"
                        (click)="downloadExcel()">
                        <img _ngcontent-agq-c177="" src="/assets/img/icons/csv.svg" alt="dashboard" height="15px"
                            width="30px">
                        Export excel
                    </button>
                </div>
            </div>

        </div>

    </div>

    <div class="col-lg-12 block-fliter">
        <div class="day-filter" [ngClass]="all ? 'active' : ''" (click)="changeSelectedRange('all')">
            Toutes
        </div>

        <div class="day-filter" [ngClass]="success ? 'active' : ''" (click)="changeSelectedRange('success')">
            Succès
        </div>

        <div class="day-filter" [ngClass]="fail ? 'active' : ''" (click)="changeSelectedRange('fail')">
            Echecs
        </div>

        <div class="day-filter" [ngClass]="abandonned ? 'active' : ''" (click)="changeSelectedRange('abandonned')">
            Abandonées
        </div>
    </div>

    <table class="table border-bottom">

        <thead>
            <tr>
                <th *ngFor="let head of headElements; let i = index" scope="col">
                    {{head}}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let el of transactions|filter:term | paginate: { itemsPerPage: 10, currentPage: p }; let i = index;">

                <th scope="row">{{ (i + 1) + (page * 10) }}</th>

                <td>
                    {{ el.createdAt | date:'dd-MM-YYYY HH:mm:ss' }}
                </td>

                <td>
                    {{ el.merchantReference }}
                </td>

                <td>
                    -
                </td>

                <td>
                    {{ el.sender ? el.sender.firstName : '-' }}
                </td>

                <td>
                    {{ el.sender ? el.sender.lastName : '-' }}
                </td>

                <td>
                    {{ el.phone }}
                </td>

                <td>
                    {{ el.countryCode }}
                </td>

                <td>
                    {{ el.mnoName == 'orange' || el.mnoName == 'free' || el.mnoName == 'mtn' || el.mnoName == 'moov' ? 'Mobile Money' : 'Carte bancaire' }}
                </td>

                <td>
                    {{ el.mnoName }}
                </td>

                <td>
                    <!-- {{ el.mno == 'orange' || el.mno == 'free' || el.mno == 'mtn' || el.mno == 'moov' ? 'Mobile Money' : 'Carte bancaire' }} -->
                    {{ el.amount }}
                </td>

                <td>
                    <span class="badge" [ngClass]="{
                            'badge-warning': el.status.toLowerCase() === 'inprogress',
                            'badge-danger': el.status.toLowerCase() === 'failed',
                            'badge-success': el.status.toLowerCase() === 'success' || el.status.toLowerCase() === 'successful',
                            'badge-primary': el.status.toLowerCase() === 'expired',
                            'badge-secondary': el.status.toLowerCase() === 'abandonned'
                        }">
                        {{el.status}}
                    </span>
                </td>

                <td>
                    <button class="action-button mr-2" id="dropdownManual" (click)="open(content, el.id)">
                        <img _ngcontent-agq-c177="" src="/assets/img/details.png" alt="dashboard" width="20px">
                    </button>
                </td>
            </tr>

            <!-- <tr *ngIf="!getLoader()">
                <td colspan="10" class="text-center">
                    <span class="spinner-border spinner-border-lg align-center"></span>
                </td>
            </tr> -->

        </tbody>
    </table>

    <div class="mb-25 mt-25 col-lg 12 container-fluid float-left">
        <div class="col-span-3 pr-10">
            <pagination-controls
                (pageChange)="p = $event"
                responsive="true"
                previousLabel="<"
                nextLabel=">" class="float-right"></pagination-controls>
        </div>

        <!-- <button class="content-shadow white-button float-right" (click)="nextPage()">
            Suivant
        </button>

        <button class="content-shadow white-button float-right mr-15" (click)="previousPage()">
            Précédent
        </button>

        <div class="float-right mr-25 gva-table-pagination">
            Pages <strong>{{ page + 1 }}</strong> sur <strong>{{ pageSize == 0 ? 1 : pageSize }}</strong>
        </div> -->

    </div>


    <ng-template #content let-modal>
        <div class="modal-body none-padding">
            <div class="col-md-12 none-padding">
                <div class="row">
                    <div class="col-md-5 left-block text-center">
                        <img _ngcontent-agq-c177="" src="/assets/img/list-current-transaction.png" alt="dashboard"
                            width="100px">
                        <h5>
                            N° {{ currentTransaction[0].referenceNumber }}
                        </h5>
                        <h3>
                            {{ currentTransaction[0].amount }} FCFA
                        </h3>

                        <h6>
                            {{ currentTransaction[0].date | date:'fullDate':'UTC' }}
                        </h6>
                    </div>

                    <div class="col-md-7 rigth-block">
                        <h6> Details transaction </h6>
                        <div class="row">
                            <div class="col-md-5">
                                <ul class="list-item-title">
                                    <li>
                                        Date :
                                    </li>
                                    <li>
                                        Partenaire :
                                    </li>
                                    <li>
                                        Transaction ID :
                                    </li>

                                    <li>
                                        Prénom :
                                    </li>
                                    <li>
                                        Nom :
                                    </li>

                                    <li>
                                        E-mail :
                                    </li>

                                    <li>
                                        Téléphone :
                                    </li>

                                    <li>
                                        Offre :
                                    </li>

                                    <li>
                                        country :
                                    </li>
                                    <li>
                                        Opérateur :
                                    </li>
                                    <li>
                                        Partner reference :
                                    </li>
                                    <li>
                                        Montant :
                                    </li>
                                    <li>
                                        Devise :
                                    </li>
                                    <li>
                                        Statut :
                                    </li>
                                </ul>
                            </div>

                            <div class="col-md-7">
                                <ul class="list-item-values">
                                    <li>
                                        {{ currentTransaction[0].createdAt | date:'fullDate':'UTC' }}
                                    </li>

                                    <li>
                                        {{ currentTransaction[0].merchantReference }}
                                    </li>

                                    <li>
                                        -
                                    </li>

                                    <li>
                                        {{ currentTransaction[0].sender ? currentTransaction[0].sender.firstName : '-' }}
                                    </li>

                                    <li>
                                        {{ currentTransaction[0].sender ? currentTransaction[0].sender.lastName : '-' }}
                                    </li>

                                    <li>
                                        {{ currentTransaction[0].sender ? currentTransaction[0].sender.address : '-' }}
                                    </li>

                                    <li>
                                        7777777777
                                    </li>

                                    <li>
                                        -
                                    </li>

                                    <li>
                                        {{ currentTransaction[0].country }}
                                    </li>

                                    <li>
                                        {{ currentTransaction[0].mnoName }}
                                    </li>

                                    <li>
                                        {{ currentTransaction[0].merchantReference }}
                                    </li>

                                    <li>
                                        {{ currentTransaction[0].amount }}
                                    </li>

                                    <li>
                                        {{ currentTransaction[0].currency }}
                                    </li>

                                    <li>
                                        {{ currentTransaction[0].status }}
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <!-- <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">Transaction</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div> -->

        </div>
    </ng-template>

</div>
