<div class="col-md-12 breadcrumbs-block">
    <div class="row">
        
        <div class="col-md-6">
            <strong>App</strong> <span class="breadcrumbs-color"> > Configuration > Ajouter un utilisateur</span>
        </div>

        <div class="col-md-6">
        </div>
    </div>
</div>

<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 white-background none-padding pt-25 pb-25 content-shadow">

    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-25 border-bottom">
        <h5 *ngIf="isAddMode" class="text-left">Ajouter un utilisateur</h5>
        <h5 *ngIf="!isAddMode" class="text-left">Modifier un utilisateur</h5>
    </div>

    <form [formGroup]="form" (ngSubmit)="onSubmit()" class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="form-row">

            <div class="form-group col">
                <label for="lastName">Nom</label>
                <input type="text" formControlName="lastName" class="form-control" 
                    [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
                <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                    <div *ngIf="f.lastName.errors.required">Nom est obligatoire</div>
                </div>
            </div>

            <div class="form-group col">
                <label for="firstName">Prénom</label>

                <input type="text" formControlName="firstName" id="firstName" class="form-control" 
                    [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />

                <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                    <div *ngIf="f.firstName.errors.required">Prénom est obligatoire</div>
                </div>
            </div>

        </div>

        <div class="form-row">
            <div class="form-group col">
                <label for="emailAddress">E-mail</label>

                <input type="emailAddress" formControlName="emailAddress" class="form-control" 
                    [ngClass]="{ 'is-invalid': submitted && f.emailAddress.errors }" />

                <div *ngIf="submitted && f.emailAddress.errors" class="invalid-feedback">
                    <div *ngIf="f.emailAddress.errors.required">E-mail est obligatoire</div>
                </div>
            </div>
            <div class="form-group col">
                <label for="address">Adresse</label>
                <input type="text" formControlName="address" class="form-control" 
                    [ngClass]="{ 'is-invalid': submitted && f.address.errors }" />
                <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                    <div *ngIf="f.address.errors.required">Adresse est obligatoire</div>
                </div>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col">
                <label for="profil">Profil</label>
                <!-- <span *ngIf="!profils" class="spinner-border spinner-border-sm mr-1 spinner-select"></span> -->

                <select class="form-control" id="profil" name="profil"
                    formControlName="profil"
                    [ngClass]="{ 'is-invalid': submitted && f.profil.errors }" required>

                    <option value="" disabled>Sélectionnez un profil</option>

                    <option
                        *ngFor="let profil of profils"
                        [selected] = "form.value.profil == profil.name"
                        [ngValue]="profil.id">
                        {{ profil?.name }}
                    </option>

                    <!-- <option [selected] = "true" value="user">
                        User
                    </option>
                    <option value="admin">
                        Admin
                    </option> -->
                </select>

                <div *ngIf="submitted && f.profil.errors" class="invalid-feedback">
                    <div *ngIf="f.profil.errors.required">Profil est obligatoire</div>
                </div>
            </div>
            <div class="form-group col">
                <label for="phoneNumber">Téléphone</label>

                <input type="text" formControlName="phoneNumber" class="form-control" 
                    [ngClass]="{ 'is-invalid': submitted && f.phoneNumber.errors }" />

                <div *ngIf="submitted && f.phoneNumber.errors" class="invalid-feedback">
                    <div *ngIf="f.phoneNumber.errors.required">Téléphone est obligatoire</div>
                </div>
            </div>
        </div>

        <!-- <div class="form-row">
            <div class="form-group col-lg-6" *ngIf="!isAddMode">
                <label for="password">
                    Mot de passe
                    <em>(Laissez vide pour garder le même mot de passe)</em>
                </label>
                <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.required">Mot de passe est obligatoire</div>
                    <div *ngIf="f.password.errors.minlength">Mot de passe doit avoir 6 caractéres</div>
                </div>
            </div>

        </div> -->


        <div class="form-group text-center mt-25">
            
            <a routerLink="/users" class="white-button mr-15">Annuler</a>

            <button [disabled]="loading" class="orange-button" type="submit">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                Enregistrer
            </button>
        </div>
    </form>

</div>
