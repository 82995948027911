import { Component, OnInit } from '@angular/core';
import { NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { TransactionService } from '@app/_services/transaction.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-filter',
	templateUrl: './filter.component.html',
	styleUrls: ['./filter.component.css']
})

export class FilterComponent implements OnInit {

	hoveredDate: NgbDate | null = null;

	country = '';
	payment = '';
	operatorFilter = '';

	fromDate: NgbDate | null;
	toDate: NgbDate | null;
	transactionSubscription: Subscription;

	countryTab = [];
	paymentMode = [];
	operator = [];

	constructor(private calendar: NgbCalendar,
		public formatter: NgbDateParserFormatter,
		private transactionService: TransactionService) {

		let range = this.getWeekRange();
		let firstDay = new Date(range[0].firstday);
		let lastday = new Date(range[0].lastday);

		this.fromDate = new NgbDate(firstDay.getFullYear(), firstDay.getMonth() + 1, firstDay.getDate());
		this.toDate = new NgbDate(lastday.getFullYear(), lastday.getMonth() + 1, lastday.getDate());

		// this.setWeekRange();
	}

	ngOnInit(): void {
		this.transactionSubscription = this.transactionService.transactionsSubject.subscribe(
			(transactions: any[]) => {
				console.log('transactions filter : ', transactions);
				//this.setWeekRange();
			}
		);
		this.transactionService.emitTransactions();
	}

	onDateSelection(date: NgbDate) {
		if (!this.fromDate && !this.toDate) {
			this.fromDate = date;
		} else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
			this.toDate = date;
			this.setWeekRange();
		} else {
			this.toDate = null;
			this.fromDate = date;
		}
	}

	isHovered(date: NgbDate) {
		return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
	}

	isInside(date: NgbDate) {
		return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
	}

	isRange(date: NgbDate) {
		return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
	}

	validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
		const parsed = this.formatter.parse(input);
		return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
	}

	onSubmit() {
		this.setWeekRange();
	}

	setWeekRange() {
		this.transactionService.setWeekRange({
			firstday: this.fromDate,
			lastday: this.toDate,
			country: this.country,
			payment: this.payment,
			operatorFilter: this.operatorFilter,
			/* routerId: this.routerId,
			referenceId: this.referenceNumber,
			email: this.email,
			numTel: this.numTel */
		});
	}

	applyFilter() {
		console.log('applyFilter : ', this.country, this.payment, this.operatorFilter,);
		this.transactionService.applyFilter({
			firstday: this.fromDate,
			lastday: this.toDate,
			country: this.country,
			payment: this.payment,
			operatorFilter: this.operatorFilter,
			/* routerId: this.routerId,
			referenceId: this.referenceNumber,
			email: this.email,
			numTel: this.numTel */
		});
	}

	getWeekRange() {
		let curr = new Date; // get current date
		let last = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
		let first = last - 6; // last day is the first day + 6

		let firstday = new Date(curr.setDate(first));
		let lastday = new Date(curr.setDate(last));

		return [{
			firstday,
			lastday
		}];
	}

}
