/**
 * @description contains offer information
 */
import { Duration, Formule } from './index';

export class Offer {
    id: number;
    offer: Formule;
    duration: Duration;
    price: number;
    description: string;
    status: boolean;

    constructor(offer: Formule, duration: Duration, montant: number, desc: string, status: boolean) {
        this.offer = offer;
        this.price = montant;
        this.description = desc;
        this.duration = duration;
        this.status = status;
    }

}
